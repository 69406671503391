import { Box, Stack, Typography } from "@mui/material";
interface IProps {
  label: string;
  value: string;
  color?: string
}

const CalcItem = (props: IProps) => {
  const { label, value, color = "#667085" } = props;
  return (
    <Box>
      <Typography color={"#667085"} fontSize={"12px"} lineHeight={"24px"}>
        {label}
      </Typography>
      <Typography
        color={color}
        fontSize={"12px"}
        lineHeight={"24px"}
        fontWeight={700}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default CalcItem;
