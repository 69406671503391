import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableActions from "./table-actions";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TablePagination,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useUpdateBranchMutation } from "src/api/settings/branches";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import { useGetMatchingAggsOrdersList } from "src/api/matching/aggregators";
import { useParams } from "react-router-dom";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";

const OrdersMatchingTable = () => {
  const { t } = useTranslation("matching");
  const { t: generalT } = useTranslation("general");
  const params = useParams();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [matchStatus, setMatchStatus] = useState("all");

  const tableHeadText = [
    t("reference"),
    t("aggregator"),
    t("orderReference"),
    t("period"),
    t("timePeriod"),
    t("result"),
    t("total"),
    t("totalDifferent"),
    t("actions"),
  ];

  // APIS
  const { data, isLoading, refetch } = useGetMatchingAggsOrdersList({
    matching_id: Number(params.id),
    page: pageNumber + 1,
    ...((matchStatus === "0" || matchStatus === "1") && {
      is_matched: +matchStatus,
    }),
  });
  const { status, error } = useUpdateBranchMutation();

  const emptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setMatchStatus(event.target.value as string);
  };

  useEffect(() => {
    refetch();
  }, [matchStatus]);

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  //Update .... success and error handling
  useEffect(() => {
    if (status === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("updatedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Box>
      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        gap={2}
        alignItems={"center"}
        mb={1.5}
      >
        {/*  Aggregator Select  */}
        <Box sx={{ minWidth: 200 }}>
          <FormControl fullWidth size="small">
            <InputLabel>{t("status")}</InputLabel>
            <Select
              value={matchStatus}
              label={t("status")}
              onChange={handleChange}
            >
              <MenuItem value={"all"}>{generalT("all")}</MenuItem>
              <MenuItem value={"1"}>{t("matched")}</MenuItem>
              <MenuItem value={"0"}>{t("unmatched")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <Button
            color="tertiary"
            variant="outlined"
            startIcon={<img src={FilterIcon} alt="" />}
          >
            {t("filter")}
          </Button>
          <Button
            sx={{
              border: "1px solid #98A2B3",
              height: "40px",
              fontWeight: 500,
              borderRadius: "4px",
              padding: "7px 10px",
              background: "#FCFCFD",
              fontSize: "16px",
            }}
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
          >
            {t("download")}
          </Button>
        </Stack>
      </Stack>

      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "18px",
                    color: "#475467",
                    bgcolor: "#F9FAFB",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={9} />
          ) : (
            <TableBody>
              {data?.data.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.reference}</TableCell>
                  <TableCell align="center">
                    {row.matching?.aggregator}
                  </TableCell>
                  <TableCell align="center">
                    {row.aggregatorOrder?.order_id}
                  </TableCell>
                  <TableCell align="center">
                    {row.matching?.start_date} <br /> {row.matching?.end_date}
                  </TableCell>
                  <TableCell align="center">
                    {row.matching?.start_time} <br /> {row.matching?.end_time}
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Chip
                        label={t(row.is_matched ? "matched" : "unmatched")}
                        color={row.is_matched ? "success" : "error"}
                        variant="outlined"
                        sx={{ height: "22px" }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {row.aggregatorOrder?.amount}
                  </TableCell>
                  <TableCell align="center">{row.diff}</TableCell>
                  <TableCell align="center">
                    <TableActions row={row} refetch={refetch} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};
export default OrdersMatchingTable;
