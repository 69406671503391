import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import MatchingIcon from "src/assets/svg-icons/side-bar/matching.svg";
import DocumentIcon from "src/assets/svg-icons/document.svg";
import CloseCircleIcon from "src/assets/svg-icons/close.svg";
import {
  useGetAggsOrderMatches,
  useUpdateAggMatchingMutation,
} from "src/api/matching/aggregators";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";

interface IModel {
  open: boolean;
  handleClose: () => void;
  row?: any;
  refetch: Function;
}

const OrdersMatchingPopup = (props: IModel) => {
  const { open, handleClose, row, refetch } = props;
  const { aggregatorOrder, matching, suggested, matches, is_matched } = row;
  const { amount, date, status, order_id, type } = aggregatorOrder;
  const { t } = useTranslation("matching");
  const { t: generalT } = useTranslation("general");
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [selectedOrder, setSelectedOrder] = useState<any | null>(null);
  const [addedOrders, setAddedOrders] = useState<any[]>(
    is_matched ? matches : suggested
  );
  const [inputValue, setInputValue] = useState<string>("");
  const [gap, setGap] = useState<string>(row.diff || "0");
  const [note, setNote] = useState<string>(row.note || "");

  // APIS
  const { data } = useGetAggsOrderMatches({ matching_id: row.matching.id });
  const {
    mutate,
    isPending,
    error,
    status: apiStatus,
  } = useUpdateAggMatchingMutation();

  console.log({ row });

  const addedOrdersIds = addedOrders.map((order: any) => order.id);

  const totalJazrTransactions = addedOrders.reduce(
    (accum, item) => accum + item.total,
    0
  );

  const diff = amount - (totalJazrTransactions + +gap);

  const isMatching =
    amount.toFixed(2) === (totalJazrTransactions + +gap).toFixed(2);

  const handleOrderSelect = (event: React.SyntheticEvent, newValue: any) => {
    setSelectedOrder(newValue);

    if (newValue) {
      setAddedOrders((prev) => [...prev, newValue]);
      setInputValue("");
      setSelectedOrder(null);
    }
  };

  // Handle manual changes to the input field
  const handleInputChange = (
    event: React.SyntheticEvent,
    newInputValue: string
  ) => {
    if (selectedOrder === null) {
      setInputValue(newInputValue);
    }
  };

  const removeOrder = (id: number) => {
    const filteredOrders = addedOrders.filter((order) => order.id !== id);
    setAddedOrders(filteredOrders);
  };
  const handleSave = () => {
    mutate({
      AggOrderId: row.id,
      diff: +gap,
      note: note,
      orderIds: addedOrdersIds,
    });
  };

  //API success and error handling
  useEffect(() => {
    if (apiStatus === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (apiStatus === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [apiStatus]);

  console.log({ error });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", lg: 920 },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: { xs: 1, lg: 3 },
          height: { xs: "70%", md: "auto" },
          overflow: { xs: "auto", md: "" },
        }}
      >
        <Stack spacing={{ xs: 1, md: 3 }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={0}
          >
            <Stack
              direction={"row"}
              spacing={"12px"}
              alignItems={"center"}
              color={"#101828"}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"60px"}
                height={"60px"}
                bgcolor={"#D0D5DD"}
                borderRadius={"16px"}
              >
                <img src={MatchingIcon} width={"32px"} alt="" />
              </Box>
              <Typography fontSize={"32px"} lineHeight={"38.4px"}>
                {t("matching")}
              </Typography>
            </Stack>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {/* divider */}
          <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />
          <Typography fontSize={"20px"} color={"#101828"} lineHeight={"24px"}>
            {t("chooseMatch")}
          </Typography>
          <Grid container p={{ xs: 0, lg: "0 20px" }}>
            <Grid item xs={12} md={6.7}>
              <Stack spacing={1}>
                <Typography
                  fontSize={"15px"}
                  color="#343434"
                  lineHeight={"24px"}
                >
                  {t("aggTransaction")}
                </Typography>
                <Stack
                  width="100%"
                  direction={{ xs: "column", md: "row" }}
                  alignItems={{ xs: "flex-start", md: "flex-end" }}
                >
                  <Box
                    bgcolor={"#ECECEC"}
                    borderRadius={"12px"}
                    border={"1px solid #CECECE"}
                    p="19px 12px 12px 12px"
                    display={"flex"}
                    justifyContent={"space-between"}
                    flexGrow={1}
                  >
                    <Box>
                      <Typography
                        fontSize="12px"
                        color="#101828"
                        lineHeight={"20px"}
                      >
                        {date}
                      </Typography>
                      <Typography
                        fontWeight={500}
                        color="#343434"
                        lineHeight={"20px"}
                      >
                        {type}
                      </Typography>
                      <Stack direction={"row"} spacing={"4px"}>
                        <img src={DocumentIcon} alt="" />
                        <Typography
                          fontSize="14px"
                          color="#101828"
                          lineHeight={"20px"}
                        >
                          {order_id}
                        </Typography>
                      </Stack>
                    </Box>
                    <Stack
                      direction={"row"}
                      spacing={"22px"}
                      mt={{ xs: 1, md: 0 }}
                    >
                      <Typography
                        fontSize="12px"
                        color="#101828"
                        lineHeight={"20px"}
                      >
                        {t(`${status}`)}
                      </Typography>
                      <Box>
                        <Typography
                          fontSize="12px"
                          color="#101828"
                          lineHeight={"20px"}
                          textAlign={"center"}
                        >
                          {t("total")}
                        </Typography>
                        <Typography
                          fontSize="14px"
                          color="#101828"
                          lineHeight={"20px"}
                          textAlign={"center"}
                          fontWeight={500}
                        >
                          {amount}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>

                  <Box
                    bgcolor={
                      isMatching || is_matched
                        ? "#ECFDF3"
                        : "rgba(195, 175, 0, 0.17)"
                    }
                    p="6px 8px"
                    borderRadius={"100px"}
                    mx="6px"
                    mt={{ xs: 1, md: 0 }}
                  >
                    <Typography
                      fontSize={"12px"}
                      color={isMatching || is_matched ? "#085D3A" : "#C3AF00"}
                      lineHeight={"20px"}
                      fontWeight={600}
                    >
                      {t(isMatching || is_matched ? "matched" : "notMatched")}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5.3}>
              <Stack spacing={1}>
                <Typography
                  fontSize={"15px"}
                  color="#343434"
                  lineHeight={"24px"}
                >
                  {t("jazrTransactions")}
                </Typography>
                {!is_matched && (
                  <Box sx={{ maxWidth: 250 }}>
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={
                        data?.data.filter(
                          (order: any) => !addedOrdersIds?.includes(order.id)
                        ) || []
                      }
                      sx={{ width: 235 }}
                      getOptionLabel={(option: any) => option.reference}
                      value={selectedOrder}
                      onChange={handleOrderSelect}
                      inputValue={inputValue}
                      onInputChange={handleInputChange}
                      renderInput={(params: any) => (
                        <TextField {...params} label={t("searchTransaction")} />
                      )}
                    />
                  </Box>
                )}

                <Stack minHeight={"101px"} pb={"50px"} gap={1}>
                  {addedOrders.map((order: any) => {
                    return (
                      <Box
                        bgcolor={"#FFF9F5"}
                        borderRadius={"12px"}
                        p="28px 12px 12px 12px"
                        display={"flex"}
                        justifyContent={"space-between"}
                        position={"relative"}
                      >
                        {!is_matched && (
                          <IconButton
                            size="small"
                            sx={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              padding: "3px",
                            }}
                            onClick={() => removeOrder(order.id)}
                          >
                            <img src={CloseCircleIcon} alt="" />
                          </IconButton>
                        )}

                        <Box>
                          <Typography
                            fontSize="12px"
                            color="#101828"
                            lineHeight={"20px"}
                          >
                            {order.date}
                          </Typography>
                          <Typography
                            fontWeight={500}
                            color="#343434"
                            lineHeight={"20px"}
                          >
                            {order.customer_name}
                          </Typography>
                          <Stack direction={"row"} spacing={"4px"}>
                            <img src={DocumentIcon} alt="" />
                            <Typography
                              fontSize="14px"
                              color="#101828"
                              lineHeight={"20px"}
                            >
                              Ref:{order.reference}
                            </Typography>
                          </Stack>
                        </Box>
                        <Stack direction={"row"} spacing={"22px"}>
                          <Typography
                            fontSize="12px"
                            color="#101828"
                            lineHeight={"20px"}
                          >
                            {t("spent")}
                          </Typography>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color="#101828"
                              lineHeight={"20px"}
                              textAlign={"center"}
                            >
                              {t("total")}
                            </Typography>
                            <Typography
                              fontSize="14px"
                              color="#101828"
                              lineHeight={"20px"}
                              textAlign={"center"}
                              fontWeight={500}
                            >
                              {order.total}
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    );
                  })}
                </Stack>
                <Stack direction={"row"} spacing={1} mt={"55px"}>
                  <Box sx={{ maxWidth: 145 }}>
                    <TextField
                      type="number"
                      id="outlined-basic"
                      size="small"
                      label={t("deferent")}
                      variant="outlined"
                      fullWidth
                      value={gap}
                      onChange={(e) => setGap(e.target.value)}
                      disabled={is_matched}
                    />
                  </Box>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label={t("note")}
                    variant="outlined"
                    fullWidth
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    disabled={is_matched}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6.7} mt={1}>
              <Stack width="100%" direction={"row"} alignItems={"center"}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexGrow={1}
                  alignItems={"center"}
                >
                  <Typography
                    fontWeight={500}
                    color={"#101828"}
                    lineHeight={"20px"}
                  >
                    {t("total")}:
                  </Typography>
                  <Typography
                    fontWeight={500}
                    color={"#101828"}
                    lineHeight={"20px"}
                  >
                    {amount}
                  </Typography>
                </Box>

                <Box
                  bgcolor={"rgba(195, 175, 0, 0.17)"}
                  p="6px 32px"
                  borderRadius={"100px"}
                  mx="6px"
                >
                  <Typography
                    fontSize={"12px"}
                    color={"#D92D20"}
                    lineHeight={"20px"}
                    fontWeight={600}
                  >
                    {diff.toFixed(2)}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5.3} mt={1}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                height={"100%"}
              >
                <Typography
                  fontWeight={500}
                  color={"#101828"}
                  lineHeight={"20px"}
                >
                  {t("total")}:
                </Typography>
                <Typography
                  fontWeight={500}
                  color={"#101828"}
                  lineHeight={"20px"}
                >
                  {(totalJazrTransactions + +gap).toFixed(2)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Stack>
        {!is_matched && (
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            width={"100%"}
          >
            <Stack spacing={2} direction={"row"}>
              <Button
                variant="outlined"
                color="tertiary"
                fullWidth
                onClick={handleClose}
              >
                {generalT("cancel")}
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                onClick={handleSave}
                loading={isPending}
                disabled={!isMatching}
              >
                {generalT("save")}
              </LoadingButton>
            </Stack>
          </Box>
        )}
      </Stack>
    </Modal>
  );
};

export default OrdersMatchingPopup;
