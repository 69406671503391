import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BorderLinearProgress } from "src/pages/add-sales/border-linear-progress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import UploadedFile from "src/pages/add-sales/steps/calculate-sales/uploaded-file";
import { formatBytes, formatNumber } from "src/utils";
import FileUploader from "src/shared/components/file-uploader";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import logo from "src/assets/svg-icons/logo.svg";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import EndIcon from "src/assets/svg-icons/end.svg";
import YoutubeViewerBox from "src/pages/add-sales/youtube-viewer-box";
import {
  useGetPurchaseReferenceNumbers,
  useGetPurchasingTimeRange,
  useImportPurchaseMutation,
} from "src/api/purchasing/purchasing";
import { useGetBranches } from "src/api/generic";
import BoxSelect from "src/shared/components/box-select";
import ManualIcon from "src/assets/svg-icons/manual.svg";
import FoodicsIcon from "src/assets/svg-icons/foodics.svg";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import InfoBox from "src/shared/components/info-box";
import moment from "moment";

export const steps = [
  {
    id: "calculate_sales",
    name: "Calculate sales",
    fields: ["orderByItems", "orderByPayment", "has_extra_file", "order"],
  },
  {
    id: "summary",
    name: "Summary",
    fields: [],
  },
];

interface IProps {}

export const schema = Yup.object().shape({
  type: Yup.string()
    .required("Required")
    .oneOf(["excel", "foodics"] as const),

  // calculate sales
  purchasing_file: Yup.string().required("Required"),
  purchase_item_file: Yup.string().required("Required"),
  branch_id: Yup.string().required("Required"),
});

export interface FormInputs extends Yup.InferType<typeof schema> {}

type ITypes = "foodics" | "excel";

const ImportPurchasing: React.FC<IProps> = ({}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("addSales");
  const { t: generalT } = useTranslation("general");
  const { t: purchasingT } = useTranslation("purchasing");
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [currentStep, setCurrentStep] = useState(0);
  const [purchasingFile, setPurchasingFile] = useState<any>();
  const [purchasingItemsFile, setPurchasingItemsFile] = useState<any>();
  const [data, setData] = useState<any[]>([]);
  const [itemsData, setItemsData] = useState<any[]>([]);
  const [type, setType] = useState<ITypes>("foodics");

  // React hook form
  const {
    formState: { errors },
    trigger,
    getValues,
    setValue,
    reset,
    watch,
    control,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      type: "foodics",
    },
  });

  // APIs
  const { data: branchesData, isLoading: isLoadingBranches } = useGetBranches();
  const { mutate, isPending, status, error } = useImportPurchaseMutation();
  const { data: purchaseTimeRange, refetch } = useGetPurchasingTimeRange(
    watch("branch_id")
  );
  const { data: referenceNumbersData } = useGetPurchaseReferenceNumbers(
    +watch("branch_id")
  );

  const branchHasItemsFeature = branchesData?.data.find((branch) => {
    return branch.id === +watch("branch_id");
  })?.active_subscriptions?.package.has_items;

  const handlePurchasingFileUpload = (data: any, type: ITypes) => {
    console.log({ data });
    setPurchasingFile(data || undefined);
    if (data) {
      Papa.parse(data, {
        header: true,
        complete: (results: any) => {
          console.log({ results });
          setData(results.data);
          // performCalculations(results.data);
        },
        skipEmptyLines: true,
      });
    }
    // Handle the file upload data for each XLSFileUploader instance
  };

  const handleItemsFileUpload = (data: any) => {
    console.log({ data });
    setPurchasingItemsFile(data || undefined);
    if (data) {
      Papa.parse(data, {
        header: true,
        complete: (results: any) => {
          console.log({ results });
          setItemsData(results.data);
        },
        skipEmptyLines: true,
      });
    }
  };

  const handleDownloadTemplate = () => {
    // column titles
    const columns = [
      "reference",
      "supplier",
      "destination",
      "type",
      "tax",
      "additional_cost",
      "sub_total",
      "total",
      "status",
      "invoice_number",
      "invoice_date",
      "notes",
      "business_date",
      "created_at",
    ];
    const data = [
      columns, // Column titles
      // ['', '', ''], // Empty row
    ];

    // Convert data to CSV format using PapaParse
    const csvContent = Papa.unparse(data);

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a link to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "template.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
  };

  // Go to previous step
  const goBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const invalidDateRange = `${purchasingT("yourLastPurchasingTimeRange")} ${
    purchaseTimeRange?.start_date
  } to ${purchaseTimeRange?.end_date}. ${purchasingT(
    "ensureToSelectDatRangeStartingFrom"
  )} ${moment(purchaseTimeRange?.end_date)
    .add(1, "day")
    .format("YYYY-MM-DD")}.`;

  // Go to previous step
  const goNext = async () => {
    const validStep = await trigger(["branch_id"] as any[], {
      shouldFocus: true,
    });
    //not valid from
    if (!validStep) {
      return;
    }
    //no file
    if (
      !purchasingFile ||
      (type === "foodics" && branchHasItemsFeature && !purchasingItemsFile)
    ) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("uploadFile"),
        },
      ]);
      return;
    }
    //empty file
    if (
      data.length === 0 ||
      (type === "foodics" && branchHasItemsFeature && itemsData.length === 0)
    ) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("emptyFile"),
        },
      ]);
      return;
    }
    // existing reference
    let fileHasExistingReferences = false;
    data.forEach((item) => {
      if (referenceNumbersData?.reference_numbers.includes(item.reference)) {
        fileHasExistingReferences = true;
      }
    });
    if (fileHasExistingReferences) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: purchasingT("referenceExistingInSystem"),
        },
      ]);
      return;
    }
    // if (
    //   purchaseTimeRange?.start_date &&
    //   purchaseTimeRange?.end_date &&
    //   (new Date(data[data.length - 1].business_date) <
    //     new Date(purchaseTimeRange.end_date) ||
    //     new Date(data[0].business_date) >
    //       new Date(purchaseTimeRange.start_date))
    // ) {
    //   setNotifications([
    //     ...notifications,
    //     {
    //       type: "error",
    //       message: invalidDateRange,
    //     },
    //   ]);
    //   return;
    // }
    // go next step
    if (currentStep === 0 && !!purchasingFile) {
      setCurrentStep((prev) => prev + 1);
    }
    if (currentStep === 1) {
      const dataToSend: any = {
        branch_id: +getValues("branch_id"),
        type: type,
      };
      const formData = new FormData();
      for (const key in dataToSend) {
        formData.append(key, dataToSend[key]);
      }
      formData.append("purchase_file", purchasingFile);
      if (type === "foodics" && branchHasItemsFeature) {
        formData.append("purchase_item_file", purchasingItemsFile);
      }
      mutate(formData);
    }
  };

  let totalSum = 0;
  let returnTotal = 0;
  let subtotalSum = 0;
  let returnSubtotal = 0;
  let taxSum = 0;
  let returnTax = 0;
  let returnCount = 0;

  data.forEach((item) => {
    totalSum += +item.total;
    subtotalSum += +item.sub_total;
    taxSum += +item?.tax?.split(" ")[0];

    if (item.type != "Purchasing") {
      returnTotal += +item.total;
      returnSubtotal += +item.sub_total;
      returnTax += +item?.tax?.split(" ")[0];
      returnCount += 1;
    }
  });

  const totalPurchaseWithTax = totalSum - returnTotal;
  const netPurchase = subtotalSum - returnSubtotal;
  const totalTax = taxSum - returnTax;

  useEffect(() => {
    setPurchasingFile(undefined);
  }, [type]);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
      navigate("/purchasing/list");
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  console.log({ data, totalSum, returnSubtotal, taxSum, returnCount });

  return (
    <Box>
      <Box px={{ xs: 3, md: 4 }} pt={4} pb={{ xs: 1, md: 0 }}>
        <img src={logo} alt="" />
        <IconButton
          sx={{ position: "absolute", top: "30px", right: "30px" }}
          onClick={() => {
            navigate("/purchasing/list");
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display={"flex"} justifyContent={"center"} pb={"150px"}>
        <Box maxWidth={"736px"} width={"100%"} px={{ xs: 3, md: 0 }}>
          {/* Step 1 */}
          <Box sx={{ display: currentStep === 0 ? "block" : "none" }}>
            <Typography fontSize={"26px"} fontWeight={600}>
              {t("calculateYourSales")}
            </Typography>
            <Typography color={"textSecondary"}>
              {t("fillTheRequiredInfo")}
            </Typography>
            <YoutubeViewerBox videoId="djvKcUwSmqw" />
            {/* branch */}
            <FormControl
              fullWidth
              size="small"
              error={!!errors.branch_id}
              sx={{ my: "10px" }}
            >
              <InputLabel id="demo-simple-select-label">
                {generalT("branchLocation")}
              </InputLabel>
              <Controller
                name="branch_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={generalT("branchLocation")}
                  >
                    {branchesData?.data?.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={`${id}`}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.branch_id?.message}
              </FormHelperText>
            </FormControl>
            {/* {!!watch("branch_id") &&
              purchaseTimeRange?.start_date &&
              purchaseTimeRange?.end_date && (
                <Box mb={1}>
                  <InfoBox text={invalidDateRange} />
                </Box>
              )} */}
            {/* type */}
            <Box mb={"30px"}>
              <BoxSelect
                label={t("howAddCogs")}
                options={[
                  {
                    label: t("foodics"),
                    value: "foodics",
                    icon: <img src={FoodicsIcon} alt="" />,
                  },
                  {
                    label: t("excel"),
                    value: "excel",
                    icon: <img src={ManualIcon} alt="" />,
                  },
                ]}
                value={type}
                onChange={(value) => setType(value as ITypes)}
              />
            </Box>
            {type === "excel" ? (
              <Box mb={1.5}>
                <Typography mb={1}>
                  {t("toUploadYourSalesFromExcel")}
                </Typography>
                <Stack direction={"row"} alignItems={"center"} gap={0.8}>
                  <Box
                    bgcolor={"#000"}
                    width={"4px"}
                    height={"4px"}
                    borderRadius={"50%"}
                  />
                  <Typography>{t("downloadOur")}</Typography>
                  <Link
                    color={"#1A1AE6"}
                    sx={{ cursor: "pointer" }}
                    onClick={handleDownloadTemplate}
                  >
                    {t("template")}.
                  </Link>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} gap={0.8}>
                  <Box
                    bgcolor={"#000"}
                    width={"4px"}
                    height={"4px"}
                    borderRadius={"50%"}
                  />
                  <Typography>{t("fulfillRequiredColumns")}</Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} gap={0.8}>
                  <Box
                    bgcolor={"#000"}
                    width={"4px"}
                    height={"4px"}
                    borderRadius={"50%"}
                  />
                  <Typography>{t("uploadUpdatedFileHere")}</Typography>
                </Stack>
              </Box>
            ) : null}

            {/* Purchasing File */}
            <FileUploader
              label={
                type === "foodics" ? t("goToFoodicsConsolePurchasing") : ""
              }
              onFileUpload={(data) =>
                handlePurchasingFileUpload(data, "foodics")
              }
              onFileDelete={() => setPurchasingFile(undefined)}
              id="purchasing_file"
              name="purchasing_file"
              control={control}
              error={!!errors.purchasing_file}
              helperText={errors.purchasing_file?.message}
              isLoading={false}
              value={purchasingFile}
            />

            {/* Items File */}
            {type === "foodics" && branchHasItemsFeature && (
              <Box pt={2}>
                <FileUploader
                  label={t("items")}
                  onFileUpload={(data) => handleItemsFileUpload(data)}
                  onFileDelete={() => setPurchasingItemsFile(undefined)}
                  id="purchase_item_file"
                  name="purchase_item_file"
                  control={control}
                  error={!!errors.purchasing_file}
                  helperText={errors.purchasing_file?.message}
                  isLoading={false}
                  value={purchasingFile}
                />
              </Box>
            )}
          </Box>
          {/* summary */}
          {currentStep === 1 && (
            <Box>
              <Box my={3} display={"flex"} justifyContent={"center"}>
                <img src={EndIcon} alt="" />
              </Box>
              <Stack gap={1.5} alignItems={"flex-start"}>
                <Box width={"100%"}>
                  <Box height={"7px"} bgcolor={"#D0D5DD"} />
                  <Stack p={2} gap={1.5} bgcolor={"#F9FAFB"}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography fontSize={"14px"} color={"#344054"}>
                        {t("timePeriod")}
                      </Typography>
                      <Typography fontSize={"14px"} color={"#344054"}>
                        {data[0].business_date} {generalT("to")}{" "}
                        {data[data.length - 1].business_date}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography fontSize={"14px"} color={"#344054"}>
                        {generalT("Total Purchase With Tax:")}
                      </Typography>
                      <Typography fontSize={"14px"} color={"#344054"}>
                        {totalPurchaseWithTax.toFixed(2)}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography fontSize={"14px"} color={"#344054"}>
                        {generalT("Net Purchase:")}
                      </Typography>
                      <Typography fontSize={"14px"} color={"#344054"}>
                        {netPurchase.toFixed(2)}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography fontSize={"14px"} color={"#344054"}>
                        {generalT("Total Tax:")}
                      </Typography>
                      <Typography fontSize={"14px"} color={"#344054"}>
                        {totalTax.toFixed(2)}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography fontSize={"14px"} color={"#344054"}>
                        {generalT("Total Return:")}
                      </Typography>
                      <Typography fontSize={"14px"} color={"#344054"}>
                        {returnSubtotal.toFixed(2)}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography fontSize={"14px"} color={"#344054"}>
                        {generalT("Invoice Qnty:")}
                      </Typography>
                      <Typography fontSize={"14px"} color={"#344054"}>
                        {data.length - returnCount}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          )}
        </Box>
      </Box>

      {/* progress bar */}
      <Box
        bgcolor={"white"}
        zIndex={2}
        width={"100%"}
        position={"fixed"}
        sx={{ bottom: "0" }}
        boxShadow={"0 -8px 50px -5px rgba(50, 50, 50, 0.1)"}
      >
        <BorderLinearProgress
          variant="determinate"
          value={((currentStep + 1) / steps.length) * 100}
        />
        <Stack
          direction={"row"}
          justifyContent={"space-around"}
          m={3}
          gap={"10px"}
        >
          {currentStep > 0 ? (
            <Button onClick={goBack} variant="outlined" color="tertiary">
              {generalT("back")}
            </Button>
          ) : (
            <Box />
          )}

          <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
            <LoadingButton
              variant="contained"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    height: "15px",
                    transform: i18n.language === "ar" ? "scaleX(-1)" : "",
                  }}
                />
              }
              sx={{ display: "flex" }}
              onClick={goNext}
              loading={isPending}
            >
              {currentStep < steps.length - 1 ? t("next") : t("finish")}
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default ImportPurchasing;
