import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    auth: require("./locales/en/auth.json"),
    dashboard: require("./locales/en/dashboard.json"),
    aggregators: require("./locales/en/aggregators.json"),
    general: require("./locales/en/general.json"),
    operationCost: require("./locales/en/operation-cost.json"),
    settings: require("./locales/en/settings.json"),
    addSales: require("./locales/en/add-sales.json"),
    profile: require("./locales/en/profile.json"),
    home: require("./locales/en/home.json"),
    prices: require("./locales/en/prices.json"),
    reports: require("./locales/en/reports.json"),
    purchasing: require("./locales/en/purchasing.json"),
    inventory: require("./locales/en/inventory.json"),
    matching: require("./locales/en/matching.json"),
    auditing: require("./locales/en/auditing.json"),
  },
  ar: {
    auth: require("./locales/ar/auth.json"),
    dashboard: require("./locales/ar/dashboard.json"),
    aggregators: require("./locales/ar/aggregators.json"),
    general: require("./locales/ar/general.json"),
    operationCost: require("./locales/ar/operation-cost.json"),
    settings: require("./locales/ar/settings.json"),
    addSales: require("./locales/ar/add-sales.json"),
    profile: require("./locales/ar/profile.json"),
    home: require("./locales/ar/home.json"),
    prices: require("./locales/ar/prices.json"),
    reports: require("./locales/ar/reports.json"),
    purchasing: require("./locales/ar/purchasing.json"),
    inventory: require("./locales/ar/inventory.json"),
    auditing: require("./locales/ar/auditing.json"),
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
