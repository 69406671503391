import { Button, Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FixIcon from "src/assets/svg-icons/fix.svg";
import OrdersMatchingPopup from "../../orders-matching-popup";

const TableActions = ({ row, refetch }: { row: any; refetch: Function }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t: generalT } = useTranslation("general");
  const { t } = useTranslation("matching");

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      {row.is_matched ? (
        <Button
          color="tertiary"
          size="small"
          variant="outlined"
          startIcon={<VisibilityOutlinedIcon fontSize="small" />}
          onClick={handleOpen}
        >
          {t("view")}
        </Button>
      ) : (
        <Button
          size="small"
          variant="contained"
          startIcon={<img src={FixIcon} alt="" />}
          sx={{ whiteSpace: "nowrap" }}
          onClick={handleOpen}
        >
          {t("fix")}
        </Button>
      )}

      {open && (
        <OrdersMatchingPopup
          open={open}
          handleClose={handleClose}
          row={row}
          refetch={refetch}
        />
      )}
    </Stack>
  );
};
export default TableActions;
