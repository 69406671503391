interface CountryType {
  code: string;
  label: string; // English label
  label_ar: string; // Arabic label
  phone: string;
  suggested?: boolean;
}

export const countries: readonly CountryType[] = [
  { code: "AD", label: "Andorra", label_ar: "أندورا", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    label_ar: "الإمارات العربية المتحدة",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", label_ar: "أفغانستان", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    label_ar: "أنتيغوا وبربودا",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", label_ar: "أنغيلا", phone: "1-264" },
  { code: "AL", label: "Albania", label_ar: "ألبانيا", phone: "355" },
  { code: "AM", label: "Armenia", label_ar: "أرمينيا", phone: "374" },
  { code: "AO", label: "Angola", label_ar: "أنغولا", phone: "244" },
  {
    code: "AQ",
    label: "Antarctica",
    label_ar: "القارة القطبية الجنوبية",
    phone: "672",
  },
  { code: "AR", label: "Argentina", label_ar: "الأرجنتين", phone: "54" },
  {
    code: "AS",
    label: "American Samoa",
    label_ar: "ساموا الأمريكية",
    phone: "1-684",
  },
  { code: "AT", label: "Austria", label_ar: "النمسا", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    label_ar: "أستراليا",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", label_ar: "أروبا", phone: "297" },
  { code: "AX", label: "Alland Islands", label_ar: "جزر آلاند", phone: "358" },
  { code: "AZ", label: "Azerbaijan", label_ar: "أذربيجان", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    label_ar: "البوسنة والهرسك",
    phone: "387",
  },
  { code: "BB", label: "Barbados", label_ar: "بربادوس", phone: "1-246" },
  { code: "BD", label: "Bangladesh", label_ar: "بنغلاديش", phone: "880" },
  { code: "BE", label: "Belgium", label_ar: "بلجيكا", phone: "32" },
  { code: "BF", label: "Burkina Faso", label_ar: "بوركينا فاسو", phone: "226" },
  { code: "BG", label: "Bulgaria", label_ar: "بلغاريا", phone: "359" },
  { code: "BH", label: "Bahrain", label_ar: "البحرين", phone: "973" },
  { code: "BI", label: "Burundi", label_ar: "بوروندي", phone: "257" },
  { code: "BJ", label: "Benin", label_ar: "بنين", phone: "229" },
  {
    code: "BL",
    label: "Saint Barthelemy",
    label_ar: "سان بارتليمي",
    phone: "590",
  },
  { code: "BM", label: "Bermuda", label_ar: "برمودا", phone: "1-441" },
  {
    code: "BN",
    label: "Brunei Darussalam",
    label_ar: "بروناي دار السلام",
    phone: "673",
  },
  { code: "BO", label: "Bolivia", label_ar: "بوليفيا", phone: "591" },
  { code: "BR", label: "Brazil", label_ar: "البرازيل", phone: "55" },
  { code: "BS", label: "Bahamas", label_ar: "الباهاما", phone: "1-242" },
  { code: "BT", label: "Bhutan", label_ar: "بوتان", phone: "975" },
  { code: "BV", label: "Bouvet Island", label_ar: "جزيرة بوفيه", phone: "47" },
  { code: "BW", label: "Botswana", label_ar: "بوتسوانا", phone: "267" },
  { code: "BY", label: "Belarus", label_ar: "بيلاروسيا", phone: "375" },
  { code: "BZ", label: "Belize", label_ar: "بليز", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    label_ar: "كندا",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    label_ar: "جزر كوكوس (كيلينغ)",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    label_ar: "جمهورية الكونغو الديمقراطية",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    label_ar: "جمهورية أفريقيا الوسطى",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    label_ar: "جمهورية الكونغو",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", label_ar: "سويسرا", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", label_ar: "ساحل العاج", phone: "225" },
  { code: "CK", label: "Cook Islands", label_ar: "جزر كوك", phone: "682" },
  { code: "CL", label: "Chile", label_ar: "تشيلي", phone: "56" },
  { code: "CM", label: "Cameroon", label_ar: "الكاميرون", phone: "237" },
  { code: "CN", label: "China", label_ar: "الصين", phone: "86" },
  { code: "CO", label: "Colombia", label_ar: "كولومبيا", phone: "57" },
  { code: "CR", label: "Costa Rica", label_ar: "كوستاريكا", phone: "506" },
  { code: "CU", label: "Cuba", label_ar: "كوبا", phone: "53" },
  { code: "CV", label: "Cape Verde", label_ar: "الرأس الأخضر", phone: "238" },
  { code: "CW", label: "Curacao", label_ar: "كوراساو", phone: "599" },
  {
    code: "CX",
    label: "Christmas Island",
    label_ar: "جزيرة الكريسماس",
    phone: "61",
  },
  { code: "CY", label: "Cyprus", label_ar: "قبرص", phone: "357" },
  {
    code: "CZ",
    label: "Czech Republic",
    label_ar: "جمهورية التشيك",
    phone: "420",
  },
  {
    code: "DE",
    label: "Germany",
    label_ar: "ألمانيا",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", label_ar: "جيبوتي", phone: "253" },
  { code: "DK", label: "Denmark", label_ar: "الدنمارك", phone: "45" },
  { code: "DM", label: "Dominica", label_ar: "دومينيكا", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    label_ar: "جمهورية الدومينيكان",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", label_ar: "الجزائر", phone: "213" },
  { code: "EC", label: "Ecuador", label_ar: "الإكوادور", phone: "593" },
  { code: "EE", label: "Estonia", label_ar: "إستونيا", phone: "372" },
  { code: "EG", label: "Egypt", label_ar: "مصر", phone: "20" },
  {
    code: "EH",
    label: "Western Sahara",
    label_ar: "الصحراء الغربية",
    phone: "212",
  },
  { code: "ER", label: "Eritrea", label_ar: "إريتريا", phone: "291" },
  { code: "ES", label: "Spain", label_ar: "إسبانيا", phone: "34" },
  { code: "ET", label: "Ethiopia", label_ar: "إثيوبيا", phone: "251" },
  { code: "FI", label: "Finland", label_ar: "فنلندا", phone: "358" },
  { code: "FJ", label: "Fiji", label_ar: "فيجي", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    label_ar: "جزر فوكلاند",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    label_ar: "ميكرونيزيا",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", label_ar: "جزر فارو", phone: "298" },
  {
    code: "FR",
    label: "France",
    label_ar: "فرنسا",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", label_ar: "الغابون", phone: "241" },
  {
    code: "GB",
    label: "United Kingdom",
    label_ar: "المملكة المتحدة",
    phone: "44",
  },
  { code: "GD", label: "Grenada", label_ar: "غرينادا", phone: "1-473" },
  { code: "GE", label: "Georgia", label_ar: "جورجيا", phone: "995" },
  {
    code: "GF",
    label: "French Guiana",
    label_ar: "غيانا الفرنسية",
    phone: "594",
  },
  { code: "GG", label: "Guernsey", label_ar: "غيرنزي", phone: "44" },
  { code: "GH", label: "Ghana", label_ar: "غانا", phone: "233" },
  { code: "GI", label: "Gibraltar", label_ar: "جبل طارق", phone: "350" },
  { code: "GL", label: "Greenland", label_ar: "غرينلاند", phone: "299" },
  { code: "GM", label: "Gambia", label_ar: "غامبيا", phone: "220" },
  { code: "GN", label: "Guinea", label_ar: "غينيا", phone: "224" },
  { code: "GP", label: "Guadeloupe", label_ar: "جوادلوب", phone: "590" },
  {
    code: "GQ",
    label: "Equatorial Guinea",
    label_ar: "غينيا الاستوائية",
    phone: "240",
  },
  { code: "GR", label: "Greece", label_ar: "اليونان", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    label_ar: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", label_ar: "غواتيمالا", phone: "502" },
  { code: "GU", label: "Guam", label_ar: "غوام", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", label_ar: "غينيا بيساو", phone: "245" },
  { code: "GY", label: "Guyana", label_ar: "غيانا", phone: "592" },
  { code: "HK", label: "Hong Kong", label_ar: "هونغ كونغ", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    label_ar: "جزيرة هيرد وجزر ماكدونالد",
    phone: "672",
  },
  { code: "HN", label: "Honduras", label_ar: "هندوراس", phone: "504" },
  { code: "HR", label: "Croatia", label_ar: "كرواتيا", phone: "385" },
  { code: "HT", label: "Haiti", label_ar: "هايتي", phone: "509" },
  { code: "HU", label: "Hungary", label_ar: "المجر", phone: "36" },
  { code: "ID", label: "Indonesia", label_ar: "إندونيسيا", phone: "62" },
  { code: "IE", label: "Ireland", label_ar: "أيرلندا", phone: "353" },
  { code: "IL", label: "Israel", label_ar: "إسرائيل", phone: "972" },
  { code: "IM", label: "Isle of Man", label_ar: "جزيرة مان", phone: "44" },
  { code: "IN", label: "India", label_ar: "الهند", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    label_ar: "إقليم المحيط الهندي البريطاني",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", label_ar: "العراق", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    label_ar: "إيران",
    phone: "98",
  },
  { code: "IS", label: "Iceland", label_ar: "آيسلندا", phone: "354" },
  { code: "IT", label: "Italy", label_ar: "إيطاليا", phone: "39" },
  { code: "JE", label: "Jersey", label_ar: "جيرسي", phone: "44" },
  { code: "JM", label: "Jamaica", label_ar: "جامايكا", phone: "1-876" },
  { code: "JO", label: "Jordan", label_ar: "الأردن", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    label_ar: "اليابان",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", label_ar: "كينيا", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", label_ar: "قيرغيزستان", phone: "996" },
  { code: "KH", label: "Cambodia", label_ar: "كمبوديا", phone: "855" },
  { code: "KI", label: "Kiribati", label_ar: "كيريباس", phone: "686" },
  { code: "KM", label: "Comoros", label_ar: "جزر القمر", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    label_ar: "سانت كيتس ونيفيس",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    label_ar: "كوريا الشمالية",
    phone: "850",
  },
  {
    code: "KR",
    label: "Korea, Republic of",
    label_ar: "كوريا الجنوبية",
    phone: "82",
  },
  { code: "KW", label: "Kuwait", label_ar: "الكويت", phone: "965" },
  {
    code: "KY",
    label: "Cayman Islands",
    label_ar: "جزر كايمان",
    phone: "1-345",
  },
  { code: "KZ", label: "Kazakhstan", label_ar: "كازاخستان", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    label_ar: "لاوس",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", label_ar: "لبنان", phone: "961" },
  { code: "LC", label: "Saint Lucia", label_ar: "سانت لوسيا", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", label_ar: "ليختنشتاين", phone: "423" },
  { code: "LK", label: "Sri Lanka", label_ar: "سريلانكا", phone: "94" },
  { code: "LR", label: "Liberia", label_ar: "ليبيريا", phone: "231" },
  { code: "LS", label: "Lesotho", label_ar: "ليسوتو", phone: "266" },
  { code: "LT", label: "Lithuania", label_ar: "ليتوانيا", phone: "370" },
  { code: "LU", label: "Luxembourg", label_ar: "لوكسمبورغ", phone: "352" },
  { code: "LV", label: "Latvia", label_ar: "لاتفيا", phone: "371" },
  { code: "LY", label: "Libya", label_ar: "ليبيا", phone: "218" },
  { code: "MA", label: "Morocco", label_ar: "المغرب", phone: "212" },
  { code: "MC", label: "Monaco", label_ar: "موناكو", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    label_ar: "مولدوفا",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", label_ar: "الجبل الأسود", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    label_ar: "سانت مارتن",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", label_ar: "مدغشقر", phone: "261" },
  {
    code: "MH",
    label: "Marshall Islands",
    label_ar: "جزر مارشال",
    phone: "692",
  },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    label_ar: "مقدونيا",
    phone: "389",
  },
  { code: "ML", label: "Mali", label_ar: "مالي", phone: "223" },
  { code: "MM", label: "Myanmar", label_ar: "ميانمار", phone: "95" },
  { code: "MN", label: "Mongolia", label_ar: "منغوليا", phone: "976" },
  { code: "MO", label: "Macao", label_ar: "ماكاو", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    label_ar: "جزر ماريانا الشمالية",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", label_ar: "مارتينيك", phone: "596" },
  { code: "MR", label: "Mauritania", label_ar: "موريتانيا", phone: "222" },
  { code: "MS", label: "Montserrat", label_ar: "مونتسرات", phone: "1-664" },
  { code: "MT", label: "Malta", label_ar: "مالطا", phone: "356" },
  { code: "MU", label: "Mauritius", label_ar: "موريشيوس", phone: "230" },
  { code: "MV", label: "Maldives", label_ar: "المالديف", phone: "960" },
  { code: "MW", label: "Malawi", label_ar: "مالاوي", phone: "265" },
  { code: "MX", label: "Mexico", label_ar: "المكسيك", phone: "52" },
  { code: "MY", label: "Malaysia", label_ar: "ماليزيا", phone: "60" },
  { code: "MZ", label: "Mozambique", label_ar: "موزمبيق", phone: "258" },
  { code: "NA", label: "Namibia", label_ar: "ناميبيا", phone: "264" },
  {
    code: "NC",
    label: "New Caledonia",
    label_ar: "كاليدونيا الجديدة",
    phone: "687",
  },
  { code: "NE", label: "Niger", label_ar: "النيجر", phone: "227" },
  {
    code: "NF",
    label: "Norfolk Island",
    label_ar: "جزيرة نورفولك",
    phone: "672",
  },
  { code: "NG", label: "Nigeria", label_ar: "نيجيريا", phone: "234" },
  { code: "NI", label: "Nicaragua", label_ar: "نيكاراغوا", phone: "505" },
  { code: "NL", label: "Netherlands", label_ar: "هولندا", phone: "31" },
  { code: "NO", label: "Norway", label_ar: "النرويج", phone: "47" },
  { code: "NP", label: "Nepal", label_ar: "نيبال", phone: "977" },
  { code: "NR", label: "Nauru", label_ar: "ناورو", phone: "674" },
  { code: "NU", label: "Niue", label_ar: "نيوي", phone: "683" },
  { code: "NZ", label: "New Zealand", label_ar: "نيوزيلندا", phone: "64" },
  { code: "OM", label: "Oman", label_ar: "عمان", phone: "968" },
  { code: "PA", label: "Panama", label_ar: "بنما", phone: "507" },
  { code: "PE", label: "Peru", label_ar: "بيرو", phone: "51" },
  {
    code: "PF",
    label: "French Polynesia",
    label_ar: "بولينيزيا الفرنسية",
    phone: "689",
  },
  {
    code: "PG",
    label: "Papua New Guinea",
    label_ar: "بابوا غينيا الجديدة",
    phone: "675",
  },
  { code: "PH", label: "Philippines", label_ar: "الفلبين", phone: "63" },
  { code: "PK", label: "Pakistan", label_ar: "باكستان", phone: "92" },
  { code: "PL", label: "Poland", label_ar: "بولندا", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    label_ar: "سان بيير وميكلون",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", label_ar: "بيتكيرن", phone: "870" },
  { code: "PR", label: "Puerto Rico", label_ar: "بورتوريكو", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    label_ar: "فلسطين",
    phone: "970",
  },
  { code: "PT", label: "Portugal", label_ar: "البرتغال", phone: "351" },
  { code: "PW", label: "Palau", label_ar: "بالاو", phone: "680" },
  { code: "PY", label: "Paraguay", label_ar: "باراغواي", phone: "595" },
  { code: "QA", label: "Qatar", label_ar: "قطر", phone: "974" },
  { code: "RE", label: "Reunion", label_ar: "ريونيون", phone: "262" },
  { code: "RO", label: "Romania", label_ar: "رومانيا", phone: "40" },
  { code: "RS", label: "Serbia", label_ar: "صربيا", phone: "381" },
  { code: "RU", label: "Russian Federation", label_ar: "روسيا", phone: "7" },
  { code: "RW", label: "Rwanda", label_ar: "رواندا", phone: "250" },
  {
    code: "SA",
    label: "Saudi Arabia",
    label_ar: "المملكة العربية السعودية",
    phone: "966",
  },
  {
    code: "SB",
    label: "Solomon Islands",
    label_ar: "جزر سليمان",
    phone: "677",
  },
  { code: "SC", label: "Seychelles", label_ar: "سيشل", phone: "248" },
  { code: "SD", label: "Sudan", label_ar: "السودان", phone: "249" },
  { code: "SE", label: "Sweden", label_ar: "السويد", phone: "46" },
  { code: "SG", label: "Singapore", label_ar: "سنغافورة", phone: "65" },
  { code: "SH", label: "Saint Helena", label_ar: "سانت هيلينا", phone: "290" },
  { code: "SI", label: "Slovenia", label_ar: "سلوفينيا", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    label_ar: "سفالبارد ويان ماين",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", label_ar: "سلوفاكيا", phone: "421" },
  { code: "SL", label: "Sierra Leone", label_ar: "سيراليون", phone: "232" },
  { code: "SM", label: "San Marino", label_ar: "سان مارينو", phone: "378" },
  { code: "SN", label: "Senegal", label_ar: "السنغال", phone: "221" },
  { code: "SO", label: "Somalia", label_ar: "الصومال", phone: "252" },
  { code: "SR", label: "Suriname", label_ar: "سورينام", phone: "597" },
  { code: "SS", label: "South Sudan", label_ar: "جنوب السودان", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    label_ar: "ساو تومي وبرينسيبي",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", label_ar: "السلفادور", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    label_ar: "سينت مارتن",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    label_ar: "سوريا",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", label_ar: "سوازيلاند", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    label_ar: "جزر تركس وكايكوس",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", label_ar: "تشاد", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    label_ar: "الأقاليم الجنوبية الفرنسية",
    phone: "262",
  },
  { code: "TG", label: "Togo", label_ar: "توغو", phone: "228" },
  { code: "TH", label: "Thailand", label_ar: "تايلاند", phone: "66" },
  { code: "TJ", label: "Tajikistan", label_ar: "طاجيكستان", phone: "992" },
  { code: "TK", label: "Tokelau", label_ar: "توكيلاو", phone: "690" },
  { code: "TL", label: "Timor-Leste", label_ar: "تيمور الشرقية", phone: "670" },
  { code: "TM", label: "Turkmenistan", label_ar: "تركمانستان", phone: "993" },
  { code: "TN", label: "Tunisia", label_ar: "تونس", phone: "216" },
  { code: "TO", label: "Tonga", label_ar: "تونغا", phone: "676" },
  { code: "TR", label: "Turkey", label_ar: "تركيا", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    label_ar: "ترينيداد وتوباغو",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", label_ar: "توفالو", phone: "688" },
  {
    code: "TW",
    label: "Taiwan",
    label_ar: "تايوان",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    label_ar: "جمهورية تنزانيا",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", label_ar: "أوكرانيا", phone: "380" },
  { code: "UG", label: "Uganda", label_ar: "أوغندا", phone: "256" },
  {
    code: "US",
    label: "United States",
    label_ar: "الولايات المتحدة",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", label_ar: "أوروغواي", phone: "598" },
  { code: "UZ", label: "Uzbekistan", label_ar: "أوزبكستان", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    label_ar: "الفاتيكان",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    label_ar: "سانت فنسنت وجزر غرينادين",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", label_ar: "فنزويلا", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    label_ar: "جزر العذراء البريطانية",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    label_ar: "جزر العذراء الأمريكية",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", label_ar: "فيتنام", phone: "84" },
  { code: "VU", label: "Vanuatu", label_ar: "فانواتو", phone: "678" },
  {
    code: "WF",
    label: "Wallis and Futuna",
    label_ar: "واليس وفوتونا",
    phone: "681",
  },
  { code: "WS", label: "Samoa", label_ar: "ساموا", phone: "685" },
  { code: "XK", label: "Kosovo", label_ar: "كوسوفو", phone: "383" },
  { code: "YE", label: "Yemen", label_ar: "اليمن", phone: "967" },
  { code: "YT", label: "Mayotte", label_ar: "مايوت", phone: "262" },
  { code: "ZA", label: "South Africa", label_ar: "جنوب أفريقيا", phone: "27" },
  { code: "ZM", label: "Zambia", label_ar: "زامبيا", phone: "260" },
  { code: "ZW", label: "Zimbabwe", label_ar: "زيمبابوي", phone: "263" },
];
