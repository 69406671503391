import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHeadText from "./table-head-text";
import { Box } from "@mui/material";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";


const TransactionsTable = ({
  data,
  isFetching,
}: {
  data: any;
  isFetching: boolean;
}) => {
  const emptyDataArr = data?.length === 0;
  const { t } = useTranslation("aggregators");
  const { t: generalT } = useTranslation("general");

  const HeadText = [
    t("reference"),
    t("branch"),
    t("aggName"),
    `${t("aggFees")}%`,
    t("period"),
    t("orderQnty"),
    generalT("netSalesWithTax"),
    t("cogsTotal"),
    `${t("cogs")}%`,
    t("totalAggsFeesWithoutTax"),
    t("aggDeliveryCost"),
    t("aggDeliveryCostMethod"),
    t("compensation"),
    t("otherFees"),
    t("aggBankFee"),
    t("relatedPayments"),
    t("tax"),
    t("refundTax"),
    t("createdAt"),
  ];
  return (
    <Box overflow={"auto"} maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <TableContainer component={Paper} variant="outlined">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {HeadText.map((item) => (
                <TableCell align="center" sx={{ bgcolor: "#F9FAFB" }}>
                  <TableHeadText text={item} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isFetching ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={20} />
          ) : (
            <TableBody>
              {data?.map((row: any) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.reference}</TableCell>
                  <TableCell align="center">{row.branch.name}</TableCell>
                  <TableCell align="center">{row.aggregator.name}</TableCell>
                  <TableCell align="center">
                    {row.aggregator_fee_percentage}%
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: "130px" }}>
                    {row.start_date} {generalT("to")} {row.end_date}
                  </TableCell>
                  <TableCell align="center">{formatNumber(row.quantity)}</TableCell>
                  <TableCell align="center">{formatNumber(row.sales)}</TableCell>
                  <TableCell align="center">{formatNumber(row.cogs)}</TableCell>
                  <TableCell align="center">{row.cogs_percentage}%</TableCell>
                  <TableCell align="center">{formatNumber(row.aggregator_fee)}</TableCell>
                  <TableCell align="center">{formatNumber(row.delivery_fee)}</TableCell>
                  <TableCell align="center">{row.delivery_type}</TableCell>
                  <TableCell align="center">{formatNumber(row.compensation)}</TableCell>
                  <TableCell align="center">{formatNumber(row.other_fee)}</TableCell>
                  <TableCell align="center">{formatNumber(row.bank_fee)}</TableCell>
                  <TableCell align="center" sx={{minWidth:"130px"}}>
                    {row?.aggregator.related_payment_methods.map(
                      (payment: any, index: number) =>
                        `${payment.payment_method.name}${
                          index !== row.aggregator.related_payment_methods?.length - 1
                            ? " - "
                            : ""
                        }`
                    )}
                  </TableCell>
                  <TableCell align="center">{formatNumber(row.tax)}</TableCell>
                  <TableCell align="center">{formatNumber(row.refund_tax)}</TableCell>
                  <TableCell align="center" dir="ltr" sx={{ minWidth: "130px" }}>
                    {row.created_at}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
    </Box>
  );
};
export default TransactionsTable;
