import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { useGetBranches } from "src/api/generic";
import {
  useAddPaymentMutation,
  useUpdatePaymentMutation,
} from "src/api/settings/payments";
import { LoadingButton } from "@mui/lab";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  name: yup.string().required("Required"),
  balance: yup.number().required("Required"),
});

interface FormInputs extends yup.InferType<typeof schema> {}

interface IModel {
  open: boolean;
  handleClose: () => void;
  paymentData?: any;
  refetch: Function;
}

const AddEditPayment = (props: IModel) => {
  const { open, handleClose, paymentData, refetch } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const mode = !paymentData ? "add" : "edit";
  const { control, handleSubmit, formState, reset } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const { t } = useTranslation("settings");
  const { t: generalT } = useTranslation("general");
  // APIS
  const { mutate, isPending, status, error } = useAddPaymentMutation();
  const {
    mutate: mutateUpdate,
    isPending: isPendingUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useUpdatePaymentMutation();
  const { data: branchesData } = useGetBranches();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (mode === "add") {
      mutate(data);
    } else {
      mutateUpdate({
        id: paymentData.id,
        ...data,
      });
    }
  };

  useEffect(() => {
    if (paymentData) {
      reset({
        name: paymentData.name,
        balance: paymentData.balance,
      });
    }
  }, [paymentData]);

  //Add user success and error handling
  useEffect(() => {
    if (status === "success") {
      // reset(initForm);
      handleClose();
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update user success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      // reset(initForm);
      handleClose();
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 537,
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {mode === "add" ? t("addNewPayment") : t("editPayment")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Controller
              name="name"
              control={control}
              disabled={paymentData?.hasTransactions}
              render={({ field }) => (
                <TextField
                  label={t("paymentName")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!formState.errors.name}
                  helperText={formState.errors.name?.message}
                />
              )}
            />
            <Controller
              name="balance"
              control={control}
              disabled={paymentData?.hasTransactions}
              render={({ field }) => (
                <TextField
                  label={t("startBalance")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!formState.errors.balance}
                  helperText={formState.errors.balance?.message}
                />
              )}
            />
          </Stack>
          {!paymentData?.hasTransactions && (
            <Stack spacing={2} direction={"row"} mt={3}>
              <Button
                variant="outlined"
                color="tertiary"
                fullWidth
                onClick={handleClose}
              >
                {generalT("cancel")}
              </Button>
              <LoadingButton
                type="submit"
                loading={isPending || isPendingUpdate}
                variant="contained"
                fullWidth
              >
                {mode === "add" ? generalT("add") : generalT("save")}
              </LoadingButton>
            </Stack>
          )}
        </form>
      </Stack>
    </Modal>
  );
};

export default AddEditPayment;
