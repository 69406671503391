import { Box, Stack, TablePagination, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AuditingTable from "./table";
import MatchingIcon from "src/assets/svg-icons/side-bar/matching.svg";
import { useGetAuditingList } from "src/api/auditing/auditing";
import { useEffect, useState } from "react";

const Auditing = () => {
  const { t } = useTranslation("auditing");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const { data, isFetching, refetch } = useGetAuditingList({
    page: pageNumber + 1,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta.total);
    }
  }, [data]);

  return (
    <Stack gap={3} p={{ xs: 2, sm: "8px 32px 32px 32px" }}>
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        color={"#101828"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={MatchingIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {t("auditing")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />
      <AuditingTable
        rows={data?.data}
        isLoading={isFetching}
        refetch={refetch}
      />
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </Stack>
  );
};

export default Auditing;
