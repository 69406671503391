import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import { useUpdateAuditTypeMutation } from "src/api/auditing/auditing";

const schema = yup.object().shape({
  category: yup.string().required("Required"),
});

interface FormInputs extends yup.InferType<typeof schema> {}

interface IModel {
  open: boolean;
  handleClose: () => void;
  refetch: Function;
  id: number;
  type: string;
  row: any;
}

const TypeChangePopup = (props: IModel) => {
  const { open, handleClose, refetch, type, id, row } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t } = useTranslation("auditing");
  const { t: generalT } = useTranslation("general");

  const { control, handleSubmit, formState, reset } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // APIS
  const { mutate, isPending, status, error } = useUpdateAuditTypeMutation();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    console.log({ data });
    mutate({ id: id, type: data.category });
  };

  useEffect(() => {
    reset({ category: type });
  }, [type]);

  //Update user success and error handling
  useEffect(() => {
    if (status === "success") {
      handleClose();
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  console.log({ type, id });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 537,
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {t("switch")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            fullWidth
            size="small"
            error={!!formState.errors.category}
          >
            <InputLabel id="demo-simple-select-label">
              {t("selectCategory")}
            </InputLabel>
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  key={field.value}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={t("selectCategory")}
                >
                  <MenuItem value="sale">{t("sale")}</MenuItem>
                  <MenuItem value="operationCost">{t("operationCost")}</MenuItem>
                  <MenuItem value="purchase">{t("purchase")}</MenuItem>
                </Select>
              )}
            />
            <FormHelperText id="my-helper-text">
              {formState.errors.category?.message}
            </FormHelperText>
          </FormControl>

          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              type="submit"
              loading={isPending}
              variant="contained"
              fullWidth
            >
              {generalT("confirm")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default TypeChangePopup;
