import { Box, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import InfoIcon from "src/assets/svg-icons/info.svg";

interface IProps {
  title: string;
  children: ReactNode;
  icon?: string;
}
const StatisticsCard = (props: IProps) => {
  const { title, children, icon } = props;

  return (
    <Box p={3} borderRadius={1} border={"1px solid #EAECF0"} height={"100%"}>
      <Typography
        fontSize={"16px"}
        fontWeight={700}
        lineHeight={"19.2px"}
        mb={2}
      >
        {title}
      </Typography>
      <img src={icon} alt="" />
      {children}
    </Box>
  );
};
export default StatisticsCard;
