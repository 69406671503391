import { Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CogsTable from "./table";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Cogs = () => {
  const navigate = useNavigate();
  const { t  } = useTranslation("settings");
  const handleNavigate = () => {
    navigate("/inventory/cogs/add-new-cogs");
  };
  return (
    <Stack spacing={3}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          fontWeight={600}
          lineHeight={"19.2px"}
          color={"#475467"}
        >
          {t("cogsList")}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleNavigate}

        >
         {t("addNewCogs")}
        </Button>
      </Stack>
      <CogsTable /> 
    </Stack>
  );
};

export default Cogs;
