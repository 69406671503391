import { Stack, Typography, Box, Link, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import dashboard from "src/assets/img/dashboard.png";
import dashboardAr from "src/assets/img/dashboard_ar.png";
import logo from "src/assets/svg-icons/logo.svg";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import inboxIcon from "src/assets/svg-icons/inbox.svg";
import Footer from "src/shared/components/auth-pages-footer/footer";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useForgotPasswordMutation } from "src/api/auth";
import * as yup from "yup";
import { notificationsState } from "src/store/notifications";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

interface FormInputs {
  email: string;
}

const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
});

const ForgotPasswordPage = () => {
  const [sent, setSent] = useState(false);
  const [time, setTime] = useState(60);
  const [isRunning, setIsRunning] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t, i18n } = useTranslation("auth");

  const { mutate, status, error, isPending } = useForgotPasswordMutation();

  const { control, handleSubmit, formState, getValues } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    mutate(data);
  };

  const onResend = () => {
    mutate({ email: getValues("email") });
  };

  const startTimer = () => {
    setIsRunning(true);
  };

  const resetTimer = () => {
    setTime(60);
    setIsRunning(false);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined;

    if (isRunning && time > 0) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    //reset timer when time reaches 0
    if (time === 0) {
      resetTimer();
    }

    return () => clearInterval(timer as NodeJS.Timeout);
  }, [isRunning, time]);

  useEffect(() => {
    if (status === "success") {
      startTimer();
      if (sent) {
        setNotifications([
          ...notifications,
          {
            type: "success",
            message: t("emailSent"),
          },
        ]);
      } else {
        setSent(true);
      }
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Grid container p={3}>
      <Grid
        xs={12}
        md={5}
        px={{ xs: "0", lg: "80px" }}
        pt={{ xs: "16px", md: "46px" }}
        flexDirection={"column"}
        display={"flex"}
      >
        <Stack spacing={1} direction={"row"} alignItems={"center"} pb={"42px"}>
          <Link
            color={"#475467"}
            component={RouterLink}
            underline="hover"
            to="/login"
            fontWeight={600}
          >
            {t("login")}
          </Link>
          <ArrowForwardIosRoundedIcon
            sx={{ height: "16px", width: "16px", color: "#D0D5DD" }}
          />
          <Box borderRadius={"4px"} bgcolor={"#EAECF0"} px={1} py={"4px"}>
            <Typography fontSize={16} fontWeight={600} lineHeight={"19.2px"}>
              {t("forgotPassword")}
            </Typography>
          </Box>
        </Stack>
        <img src={logo} alt="jazar logo" width={"90px"} height={"42px"} />

        {sent ? (
          <Box maxWidth={"520px"}>
            <Typography
              color={"#1D2939"}
              fontSize={"32px"}
              fontWeight={600}
              lineHeight={"38.4px"}
              mb={2}
              mt={"42px"}
            >
              {t("checkYourInbox")}
            </Typography>
            <Typography
              color={"#1D2939"}
              fontSize={"16px"}
              lineHeight={"19.2px"}
              mb={"32px"}
            >
              {t("PleaseCheckYourEmail")}
            </Typography>
            <Box display={"flex"} width={"100%"} justifyContent={"center"}>
              <img src={inboxIcon} alt="" />
            </Box>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mt={"42px"}
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography fontSize={16} lineHeight={"20px"}>
                  {t("didNotReceiveMail")}
                </Typography>
                <LoadingButton
                  variant="text"
                  color="secondary"
                  size="large"
                  sx={{
                    p: 0,
                    height: "fit-content",
                  }}
                  onClick={onResend}
                  disabled={isRunning}
                  loading={isPending}
                >
                  {t("resend")}
                </LoadingButton>
              </Stack>
              {isRunning && time > 0 && <Typography>00:{time}</Typography>}
            </Stack>
          </Box>
        ) : (
          <>
            <Typography
              color={"#1D2939"}
              fontSize={"32px"}
              fontWeight={600}
              lineHeight={"38.4px"}
              mb={2}
              mt={"42px"}
            >
              {t("forgotPassword")}
              {i18n.language === "en" ? "?" : "؟"}
            </Typography>
            <Typography
              color={"#1D2939"}
              fontSize={"16px"}
              lineHeight={"19.2px"}
              mb={"42px"}
            >
              {t("enterYourEmail")}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap={2} alignItems={"flex-start"}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("email")}
                      {...field}
                      error={!!formState.errors.email}
                      helperText={formState.errors.email?.message}
                      fullWidth
                      size="small"
                    />
                  )}
                />
                <LoadingButton
                  loading={isPending}
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  {t("sendMessage")}
                </LoadingButton>
              </Stack>
            </form>
          </>
        )}

        <Box mt={{ xs: "50px", md: "165px" }}>
          <Footer />
        </Box>
      </Grid>
      <Grid
        md={7}
        display={{ xs: "none", md: "flex" }}
        flexDirection={"column"}
        alignItems={"center"}
        bgcolor={"#F9FAFB"}
        borderRadius={2}
      >
        <Typography
          color={"#0C111D"}
          fontSize="36px"
          fontWeight={600}
          lineHeight="43.2px"
          pt="59px"
          mb="22px"
          sx={{ width: { lg: "495px", md: "295px" } }}
          textAlign={"center"}
        >
          {t("nourishingYourProfits")}{" "}
          <Typography
            component={"span"}
            color={"#FF8D3F"}
            fontSize="36px"
            fontWeight={600}
            lineHeight="43.2px"
          >
            {t("oneCalculation")}{" "}
          </Typography>
          {t("atATime")}!
        </Typography>
        <Typography
          color={"#475467"}
          textAlign={"center"}
          fontSize="16px"
          lineHeight="19.2px"
          width="490px"
          mb={2}
        >
          {t("YouWillHaveTheTools")}
          <Typography
            component={"span"}
            color={"#FF8D3F"}
            fontWeight={600}
            fontSize="16px"
            lineHeight="19.2px"
          >
            {" "}
            {t("jazr")}
          </Typography>{" "}
          {t("today")}
        </Typography>
        <Box px={"40px"}>
          <Box
            boxShadow={
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
            }
            borderRadius={"10px"}
            overflow={"hidden"}
          >
            <img
              src={i18n.language === "en" ? dashboard : dashboardAr}
              alt="sing up"
              width={"100%"}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordPage;
