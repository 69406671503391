import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableActions from "./table-actions";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { Box, TablePagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import { useGetStockCountsList } from "src/api/inventory/counts";

const AllTable = () => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const { t } = useTranslation("inventory");
  const { t: generalT } = useTranslation("general");
  const tableHeadText = [
    generalT("reference"),
    generalT("branch"),
    generalT("status"),
    t("difference"),
    t("byEndOf"),
    generalT("createdBy"),
    generalT("createdAt"),
    generalT("actions"),
  ];

  // APIS
  const { data, isLoading, refetch } = useGetStockCountsList({
    page: pageNumber + 1,
  });

  const emptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  return (
    <Box>
      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "18px",
                    color: "#475467",
                    bgcolor: "#F9FAFB",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={8} />
          ) : (
            <TableBody>
              {data?.data.map((row: any) => {
                const difference = row.stock_count_items.reduce(
                  (acc: number, item: any) => acc + item.diff_cost,
                  0
                );
                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{row.reference}</TableCell>
                    <TableCell align="center">{row.branch?.name}</TableCell>
                    <TableCell align="center">
                      <Box
                        color={
                          row.status === "draft"
                            ? "#1D2939"
                            : row.status === "sent"
                            ? "#1A1AE6"
                            : "#085D3A"
                        }
                        border={"1px solid"}
                        borderColor={
                          row.status === "draft"
                            ? "#D0D5DD"
                            : row.status === "sent"
                            ? "#9999F4"
                            : "#47CD89"
                        }
                        bgcolor={
                          row.status === "draft"
                            ? "#F2F4F7"
                            : row.status === "sent"
                            ? "#E6E6FD"
                            : "#ECFDF3"
                        }
                        borderRadius={"50px"}
                        width={"fit-content"}
                        p={"4px 8px"}
                        mx={"auto"}
                      >
                        {t(`${row.status}`)}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        color={
                          difference < 0
                            ? "#F04438"
                            : difference > 0
                            ? "#00BF58"
                            : ""
                        }
                        fontSize={"14px"}
                        lineHeight={"20px"}
                      >
                        {row.status === "draft"
                          ? "-"
                          : `SAR ${difference.toFixed(3)}`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">{row.date}</TableCell>
                    <TableCell align="center">{row.created_by?.name}</TableCell>
                    <TableCell align="center">{row.created_at}</TableCell>
                    <TableCell align="center">
                      <TableActions row={row} refetch={refetch} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};
export default AllTable;
