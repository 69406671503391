import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableActions from "./table-actions";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import TableHeadText from "./table-head-text";
import { useGetCogsList } from "src/api/settings/cogs";
import { useEffect, useState } from "react";
import { Box, TablePagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";

const CogsTable = () => {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const { t: T } = useTranslation("general");

  const HeadText = [
    T("reference"),
    T("branch"),
    T("period"),
    T("netSalesWithTax"),
    T("cogsMethod"),
    T("cogsTotal"),
    `${T("cogs")} %`,
    T("createdAt"),
    T("createdBy"),
    T("actions"),
  ];

  const { data, isLoading, refetch } = useGetCogsList({
    page: pageNumber + 1,
  });

  const emptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta.total);
    }
  }, [data]);

  return (
    <Box>
      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {HeadText.map((item) => (
                <TableCell align="center" sx={{ bgcolor: "#F9FAFB" }}>
                  <TableHeadText text={item} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={10} />
          ) : (
            <TableBody>
              {data?.data.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.reference}</TableCell>
                  <TableCell align="center">{row.branch.name}</TableCell>
                  <TableCell align="center">
                    {row.start_date} {T("to")} {row.end_date}
                  </TableCell>
                  <TableCell align="center">
                    {formatNumber(row.sales)}
                  </TableCell>
                  <TableCell align="center">{row.type}</TableCell>
                  <TableCell align="center">
                    {formatNumber(row.amount)}
                  </TableCell>
                  <TableCell align="center">%{row.percentage}</TableCell>
                  <TableCell align="center" dir="ltr">
                    {row.created_at}
                  </TableCell>
                  <TableCell align="center">{row.created_by?.name}</TableCell>
                  <TableCell align="center">
                    <TableActions
                      rowId={row.id}
                      reference={row.reference}
                      refetch={refetch}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};
export default CogsTable;
