import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
  status?: string;
}

interface IStockCountBody {
  id?: number;
}

export const useGetStockCountsList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-stock-counts-list", params.page, params.status || ""],
    queryFn: async () => {
      return apiClient.get(`/api/stockCounts`, { params });
    },
  });

export const useAddStockCountMutation = () =>
  useMutation<any, APIErrorResponse, any>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/stockCounts`, data);
    },
  });

export const useUpdateStockCountMutation = () =>
  useMutation<void, APIErrorResponse, any>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/stockCounts/${id}`, rest);
    },
  });

export const useDeleteStockCountMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/stockCounts/${id}`);
    },
  });

export const useGetCountById = ({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-stock-count-by-id", id],
    queryFn: async () => {
      return apiClient.get(`/api/stockCounts/${id}`);
    },
    gcTime: 0,
    enabled,
  });

export const useImportCountMutation = () =>
  useMutation<any, APIErrorResponse, FormData>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/stockCounts/import`, data);
    },
  });
