import { Stack } from "@mui/material";
import AggregatorTable from "./table";

const Aggregator = () => {
  return (
    <Stack>
      <AggregatorTable />
    </Stack>
  );
};

export default Aggregator;
