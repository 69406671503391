import React, { useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  TextField,
  Typography,
  Stack,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useGetDraftCompanyId, useSignupMutation } from "src/api/auth";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { countries } from "../constants";

const Form: React.FC = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t, i18n } = useTranslation("auth");
  const { t: T } = useTranslation("general");

  // APIS
  const { mutate, status, isPending, error } = useSignupMutation();
  const { data: draftCompanyData } = useGetDraftCompanyId();

  // React hook form
  const schema = yup.object().shape({
    name: yup.string().required(T("required")),
    email: yup.string().email(T("invalidEmail")).required(T("required")),
    password: yup
      .string()
      .required(T("required"))
      .min(8, T("password8characters")),
    country_code: yup.string().required(T("required")),
    phone: yup.string().required(T("required")),
  });

  interface IFormInputs extends yup.InferType<typeof schema> {}

  const { control, handleSubmit, formState } = useForm<IFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      country_code: "966",
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    mutate({
      ...data,
      reference_number: Number(draftCompanyData?.data.reference_number),
    });
  };

  useEffect(() => {
    if (status === "success") {
      navigate("/login");
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("signedUpSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors?.[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} alignItems={"flex-start"} sx={{ width: "100%" }}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("fullName")}
              size="small"
              {...field}
              error={!!formState.errors.name}
              helperText={formState.errors.name?.message}
              fullWidth
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              size="small"
              label={t("email")}
              {...field}
              error={!!formState.errors.email}
              helperText={formState.errors.email?.message}
              fullWidth
            />
          )}
        />

        <Stack direction={"row"} gap={2} width={"100%"}>
          <FormControl
            sx={{ minWidth: "120px" }}
            size="small"
            error={!!formState.errors.country_code}
          >
            <InputLabel id="demo-simple-select-label">
              {t("countryCode")}
            </InputLabel>
            <Controller
              name="country_code"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  key={field.value}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={t("countryCode")}
                  renderValue={(value) => {
                    const selectedCountry = countries.find(
                      (country) => country.phone === value
                    );
                    if (!selectedCountry) return null; // Handle case when no country is selected
                    return (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${selectedCountry.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${selectedCountry.code.toLowerCase()}.png`}
                          alt={selectedCountry.label}
                        />
                        +{selectedCountry.phone}
                      </Box>
                    );
                  }}
                >
                  {countries.map((country) => (
                    <MenuItem key={country.code} value={country.phone}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          "& > img": { flexShrink: 0 },
                        }}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                          alt=""
                        />
                        <Typography fontSize={"14px"}>
                          {i18n.language === "en"
                            ? country.label
                            : country.label_ar}{" "}
                          (+
                          {country.phone})
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText id="my-helper-text">
              {formState.errors.country_code?.message}
            </FormHelperText>
          </FormControl>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField
                sx={{ flexGrow: 1 }}
                // sx={{ width: { xs: "60%", lg: "70%" } }}
                size="small"
                label={t("phoneNumber")}
                type="number"
                {...field}
                error={!!formState.errors.phone}
                helperText={formState.errors.phone?.message}
                fullWidth
              />
            )}
          />
        </Stack>

        <Stack spacing={"10px"} width="100%">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormControl
                {...field}
                variant="outlined"
                error={!!formState.errors.password}
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  {t("password")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  aria-describedby="my-helper-text"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="small"
                      >
                        {showPassword ? (
                          <VisibilityOffOutlined fontSize="small" />
                        ) : (
                          <VisibilityOutlined fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("password")}
                />
                <FormHelperText id="my-helper-text">
                  {formState.errors.password?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          <Typography color="#667085" fontSize="14px">
            {t("useCharactersMix")}.
          </Typography>
        </Stack>

        <Typography>
          {t("companyId")}:{" "}
          <strong>{draftCompanyData?.data.reference_number}</strong>{" "}
          <Typography component={"span"} color={"info"}>
            ({t("saveToUseForLogin")})
          </Typography>
        </Typography>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          loading={isPending}
        >
          {t("signUp")}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default Form;
