import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
  status?: string;
}

export const useGetAuditingList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-auditing-list", params.page, params.status || ""],
    queryFn: async () => {
      return apiClient.get(`/api/audits`, { params });
    },
  });

export const useUpdateAuditStatusMutation = () =>
  useMutation<void, APIErrorResponse, { id: number; status: string }>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/audits/update-status/${id}`, rest);
    },
  });

export const useUpdateAuditTypeMutation = () =>
  useMutation<void, APIErrorResponse, { id: number; type: string }>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/audits/update-type/${id}`, rest);
    },
  });

export const useUpdateAuditMutation = () =>
  useMutation<void, APIErrorResponse, any>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/audits/${id}`, rest);
    },
  });
