import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FixIcon from "src/assets/svg-icons/fix.svg";
import { useNavigate } from "react-router-dom";

const TableActions = ({ row, refetch }: { row: any; refetch: Function }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const { t: generalT } = useTranslation("general");
  const { t } = useTranslation("matching");
  const navigate = useNavigate();

  const navigateToOrders = () => {
    navigate(`/matching/aggregator/${row.id}`);
  };

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      <Tooltip title={generalT("attachments")}>
        <IconButton>
          <img src={DownloadIcon} alt="" />
        </IconButton>
      </Tooltip>

      <Tooltip title={generalT("delete")}>
        <IconButton onClick={handleOpen}>
          <img src={DeleteIcon} alt="" />
        </IconButton>
      </Tooltip>

      {row.is_matched ? (
        <Button
          color="tertiary"
          size="small"
          variant="outlined"
          startIcon={<VisibilityOutlinedIcon fontSize="small" />}
          onClick={navigateToOrders}
        >
          {t("view")}
        </Button>
      ) : (
        <Button
          size="small"
          variant="contained"
          startIcon={<img src={FixIcon} alt="" />}
          sx={{ whiteSpace: "nowrap" }}
          onClick={navigateToOrders}
        >
          {t("fix")}
        </Button>
      )}
    </Stack>
  );
};
export default TableActions;
