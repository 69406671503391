import {
  Stack,
  Typography,
  Link as LinkRoute,
  Box,
  Link,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import dashboard from "src/assets/img/dashboard.png";
import dashboardAr from "src/assets/img/dashboard_ar.png";
import logo from "src/assets/svg-icons/logo.svg";
import Form from "./components/form";
import Footer from "src/shared/components/auth-pages-footer/footer";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

const schema = yup.object().shape({
  password: yup.string().required("Required"),
});

export interface IFormInputs extends yup.InferType<typeof schema> {}

const LoginPage = () => {
  const { t, i18n } = useTranslation("auth");
  const [showLogin, setShowLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { control, handleSubmit, formState } = useForm<IFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    if (data.password === "j@zr@#$%/") {
      setShowLogin(true);
    }
  };

  if (!showLogin) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          gap={3}
          alignItems={"center"}
          justifyContent={"center"}
          height={"80vh"}
        >
          <Stack gap={2} width={"100%"} maxWidth={"300px"}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <FormControl
                  {...field}
                  variant="outlined"
                  error={!!formState.errors.password}
                  size="small"
                  fullWidth
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    {t("password")}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    aria-describedby="my-helper-text"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="small"
                        >
                          {showPassword ? (
                            <VisibilityOffOutlined fontSize="small" />
                          ) : (
                            <VisibilityOutlined fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t("password")}
                  />
                  <FormHelperText id="my-helper-text">
                    {formState.errors.password?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
            <Button fullWidth type="submit" variant="contained" color="primary">
              Enter
            </Button>
          </Stack>
        </Stack>
      </form>
    );
  }

  return (
    <Grid container p={3}>
      <Grid
        xs={12}
        md={5}
        px={{ xs: "0", lg: "80px" }}
        pt={{ xs: "16px", md: "46px" }}
        flexDirection={"column"}
        display={"flex"}
      >
        <img src={logo} alt="jazar logo" width={"90px"} height={"42px"} />
        <Typography
          color={"#1D2939"}
          fontSize={"32px"}
          fontWeight={600}
          lineHeight={"38.4px"}
          mb={2}
          mt={"42px"}
        >
          {t("carveYourPath")}.
        </Typography>
        <Typography
          color={"#1D2939"}
          fontSize={"16px"}
          lineHeight={"19.2px"}
          mb={"42px"}
        >
          {t("sayGoodbyeGuesswork")}.
        </Typography>
        <Form />

        <Stack
          direction={"row"}
          spacing={"4px"}
          mb={{ xs: "50px", md: "109px" }}
          alignItems={"baseline"}
          mt={4}
        >
          <Typography fontSize={"16px"} color={"#1D2939"} lineHeight={"20px"}>
            {t("doNotHaveAccount")}
          </Typography>
          <Link
            color={"#1D2939"}
            component={RouterLink}
            underline="hover"
            to="/sign-up"
            fontWeight={600}
          >
            {t("signUp")}
          </Link>
        </Stack>
        <Box flexGrow={1} display={"flex"} alignItems={"flex-end"}>
          <Footer />
        </Box>
      </Grid>
      <Grid
        md={7}
        display={{ xs: "none", md: "flex" }}
        flexDirection={"column"}
        alignItems={"center"}
        bgcolor={"#F9FAFB"}
        borderRadius={2}
      >
        <Typography
          color={"#0C111D"}
          fontSize="36px"
          fontWeight={600}
          lineHeight="43.2px"
          pt="59px"
          mb="22px"
          sx={{ width: { lg: "495px", md: "295px" } }}
          textAlign={"center"}
        >
          {t("nourishingYourProfits")}
          <Typography
            component={"span"}
            color={"#FF8D3F"}
            fontSize="36px"
            fontWeight={600}
            lineHeight="43.2px"
          >
            {" "}
            {t("oneCalculation")}
          </Typography>{" "}
          {t("atATime")}!
        </Typography>
        <Typography
          color={"#475467"}
          textAlign={"center"}
          fontSize="16px"
          lineHeight="19.2px"
          width="490px"
          mb={2}
        >
          {t("YouWillHaveTheTools")}{" "}
          <Typography
            component={"span"}
            color={"#FF8D3F"}
            fontWeight={600}
            fontSize="16px"
            lineHeight="19.2px"
          >
            {t("jazr")}
          </Typography>{" "}
          {t("today")}
        </Typography>
        <Box px={"40px"}>
          <Box
            boxShadow={
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
            }
            borderRadius={"10px"}
            overflow={"hidden"}
          >
            <img
              src={i18n.language === "en" ? dashboard : dashboardAr}
              alt="sing up"
              width={"100%"}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
