import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MatchingIcon from "src/assets/svg-icons/side-bar/matching.svg";
import { useNavigate } from "react-router-dom";
import RightArrow from "src/assets/svg-icons/aggregators/right_arrow.svg";
import OrdersMatchingTable from "./table";

const Orders = () => {
  const { t } = useTranslation("matching");
  const navigate = useNavigate();

  const navigateToMatching = () => {
    navigate("/matching");
  };

  const navigateToMatchingAggregator = () => {
    navigate("/matching/aggregator");
  };

  return (
    <Stack spacing={3} p={{ xs: 2, sm: "8px 32px 32px 32px" }}>
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        color={"#101828"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={MatchingIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {t("matching")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />
      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToMatching}
          sx={{ cursor: "pointer" }}
        >
          {t("matching")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToMatchingAggregator}
          sx={{ cursor: "pointer" }}
        >
          {t("aggregator")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Box borderRadius={"4px"} bgcolor={"#EAECF0"} p={"4px 8px"}>
          <Typography color={"#344054"} fontWeight={600} lineHeight={"19.2px"}>
            {t("MA-001")}
          </Typography>
        </Box>
      </Stack>

      <OrdersMatchingTable />
    </Stack>
  );
};

export default Orders;
