import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  useGetAccountReport,
  useGetAggregatorsReport,
  useGetForecastReport,
  useGetInventoryReport,
  useGetItemsReport,
  useGetOCReport,
  useGetPLReport,
  useGetPaymentReport,
  useGetPurchaseReport,
  useGetSupplierReport,
  useGetTaxReport,
} from "src/api/reports";
import DateRangeInput from "src/shared/components/date-range";
import ReportsIcon from "src/assets/svg-icons/side-bar/reports.svg";
import { useTranslation } from "react-i18next";
import youtubeIcon from "src/assets/svg-icons/aggregators/youtube.svg";
import { downloadFile } from "src/utils";
import ReportBox from "./report-box";
import { useGetSuppliersList } from "src/api/purchasing/suppliers";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useGetOCAccountsList } from "src/api/operation-cost/accounts";
import { useGetBranches } from "src/api/generic";

const ReportsPages = () => {
  const { t } = useTranslation("reports");
  const { t: T } = useTranslation("general");
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment().startOf("month").format("yyyy-MM-DD"),
    endDate: moment().endOf("month").format("yyyy-MM-DD"),
  });

  const [supplierId, setSupplierId] = useState("");
  const [accountId, setAccountId] = useState("");
  const [branches, setBranches] = useState<number[]>([]);

  // APIS
  const { data: suppliersData } = useGetSuppliersList();
  const { data: accountsData } = useGetOCAccountsList();
  const { data: branchesData } = useGetBranches();

  const { data, refetch, isFetching, status } = useGetOCReport({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(branches.length > 0 ? { branches: branches } : {}),
    enabled: false,
  });

  const {
    data: paymentReportData,
    refetch: refetchPaymentReport,
    isFetching: isFetchingPaymentReport,
    status: paymentReportStatus,
  } = useGetPaymentReport({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(branches.length > 0 ? { branches: branches } : {}),
    enabled: false,
  });

  const {
    data: inventoryReportData,
    refetch: refetchInventoryReport,
    isFetching: isFetchingInventoryReport,
    status: inventoryReportStatus,
  } = useGetInventoryReport({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(branches.length > 0 ? { branches: branches } : {}),
  });

  const {
    data: purchaseReportData,
    refetch: refetchPurchaseReport,
    isFetching: isFetchingPurchaseReport,
    status: purchaseReportStatus,
  } = useGetPurchaseReport({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(branches.length > 0 ? { branches: branches } : {}),
  });

  const {
    data: taxReportData,
    refetch: refetchTaxReport,
    isFetching: isFetchingTaxReport,
    status: taxReportStatus,
  } = useGetTaxReport({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(branches.length > 0 ? { branches: branches } : {}),
  });

  const {
    data: forecastReportData,
    refetch: refetchForecastReport,
    isFetching: isFetchingForecastReport,
    status: forecastReportStatus,
  } = useGetForecastReport({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(branches.length > 0 ? { branches: branches } : {}),
  });

  const {
    data: aggregatorsReportData,
    refetch: refetchAggregatorsReport,
    isFetching: isFetchingAggregatorsReport,
    status: aggregatorsReportStatus,
  } = useGetAggregatorsReport({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(branches.length > 0 ? { branches: branches } : {}),
  });

  //profit/loss report
  const {
    data: PLReportData,
    refetch: refetchPLReport,
    isFetching: isFetchingPLReport,
    status: PLReportStatus,
  } = useGetPLReport({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
  });

  const {
    data: supplierReportData,
    refetch: refetchSupplierReport,
    isFetching: isFetchingSupplierReport,
    status: supplierReportStatus,
  } = useGetSupplierReport({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    supplier_id: supplierId,
    ...(branches.length > 0 ? { branches: branches } : {}),
  });

  const {
    data: accountReportData,
    refetch: refetchAccountReport,
    isFetching: isFetchingAccountReport,
    status: accountReportStatus,
  } = useGetAccountReport({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    account_id: accountId,
    ...(branches.length > 0 ? { branches: branches } : {}),
  });

  const {
    data: itemsReportData,
    refetch: refetchItemsReport,
    isFetching: isFetchingItemsReport,
    status: itemsReportStatus,
  } = useGetItemsReport({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    branches: branches,
  });

  const handleChangeBranches = (event: any) => {
    const {
      target: { value },
    } = event;
    setBranches(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const checkBranchesSelection = () => {
    if (branches.length === 0) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("mustSelectBranches"),
        },
      ]);
      return false;
    }
    return true;
  };

  const handleDownloadOCReport = () => {
    refetch();
  };

  const handleDownloadPaymentReport = () => {
    refetchPaymentReport();
  };

  const handleDownloadInventoryReport = () => {
    refetchInventoryReport();
  };

  const handleDownloadPurchaseReport = () => {
    refetchPurchaseReport();
  };

  const handleDownloadTaxReport = () => {
    refetchTaxReport();
  };

  const handleDownloadForecastReport = () => {
    refetchForecastReport();
  };

  const handleDownloadAggregatorsReport = () => {
    refetchAggregatorsReport();
  };

  const handleDownloadPLReport = () => {
    refetchPLReport();
  };

  const handleDownloadSupplierReport = () => {
    if (!supplierId) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("mustSelectSupplier"),
        },
      ]);
      return;
    }
    refetchSupplierReport();
  };

  const handleDownloadAccountReport = () => {
    if (!accountId) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("mustSelectLiability"),
        },
      ]);
      return;
    }
    refetchAccountReport();
  };

  const handleDownloadItemsReport = () => {
    if (!checkBranchesSelection()) {
      return;
    }
    refetchItemsReport();
  };

  console.log({ data });

  useEffect(() => {
    if (status === "success" && !!data && !isFetching) {
      const utf8CSVData = `\uFEFF${data}`;
      downloadFile(utf8CSVData, "text/csv", "operation-cost-report");
    }
  }, [status, data, isFetching]);

  useEffect(() => {
    if (
      paymentReportStatus === "success" &&
      !!paymentReportData &&
      !isFetchingPaymentReport
    ) {
      const utf8CSVData = `\uFEFF${paymentReportData}`;
      downloadFile(utf8CSVData, "text/csv", "payments-report");
    }
  }, [paymentReportStatus, paymentReportData, isFetchingPaymentReport]);

  useEffect(() => {
    if (
      inventoryReportStatus === "success" &&
      !!inventoryReportData &&
      !isFetchingInventoryReport
    ) {
      const utf8CSVData = `\uFEFF${inventoryReportData}`;
      downloadFile(utf8CSVData, "text/csv", "inventory-report");
    }
  }, [inventoryReportStatus, inventoryReportData, isFetchingInventoryReport]);

  useEffect(() => {
    if (
      purchaseReportStatus === "success" &&
      !!purchaseReportData &&
      !isFetchingPurchaseReport
    ) {
      const utf8CSVData = `\uFEFF${purchaseReportData}`;
      downloadFile(utf8CSVData, "text/csv", "purchase-report");
    }
  }, [purchaseReportStatus, purchaseReportData, isFetchingPurchaseReport]);

  useEffect(() => {
    if (
      taxReportStatus === "success" &&
      !!taxReportData &&
      !isFetchingTaxReport
    ) {
      const utf8CSVData = `\uFEFF${taxReportData}`;
      downloadFile(utf8CSVData, "text/csv", "tax-report");
    }
  }, [taxReportStatus, taxReportData, isFetchingTaxReport]);

  useEffect(() => {
    if (
      forecastReportStatus === "success" &&
      !!forecastReportData &&
      !isFetchingForecastReport
    ) {
      const utf8CSVData = `\uFEFF${forecastReportData}`;
      downloadFile(utf8CSVData, "text/csv", "forecast-report");
    }
  }, [forecastReportStatus, forecastReportData, isFetchingForecastReport]);

  useEffect(() => {
    if (
      aggregatorsReportStatus === "success" &&
      !!aggregatorsReportData &&
      !isFetchingAggregatorsReport
    ) {
      const utf8CSVData = `\uFEFF${aggregatorsReportData}`;
      downloadFile(utf8CSVData, "text/csv", "aggregators-report");
    }
  }, [
    aggregatorsReportStatus,
    aggregatorsReportData,
    isFetchingAggregatorsReport,
  ]);

  useEffect(() => {
    if (PLReportStatus === "success" && !!PLReportData && !isFetchingPLReport) {
      const utf8CSVData = `\uFEFF${PLReportData}`;
      downloadFile(utf8CSVData, "text/csv", "profit-loss-report");
    }
  }, [PLReportStatus, PLReportData, isFetchingPLReport]);

  useEffect(() => {
    if (
      supplierReportStatus === "success" &&
      !!supplierReportData &&
      !isFetchingSupplierReport
    ) {
      const utf8CSVData = `\uFEFF${supplierReportData}`;
      downloadFile(utf8CSVData, "text/csv", "supplier-report");
    }
  }, [supplierReportStatus, supplierReportData, isFetchingSupplierReport]);

  useEffect(() => {
    if (
      accountReportStatus === "success" &&
      !!accountReportData &&
      !isFetchingAccountReport
    ) {
      const utf8CSVData = `\uFEFF${accountReportData}`;
      downloadFile(utf8CSVData, "text/csv", "liability-report");
    }
  }, [accountReportStatus, accountReportData, isFetchingAccountReport]);

  useEffect(() => {
    if (
      itemsReportStatus === "success" &&
      !!itemsReportData &&
      !isFetchingItemsReport
    ) {
      const utf8CSVData = `\uFEFF${itemsReportData}`;
      downloadFile(utf8CSVData, "text/csv", "items-report");
    }
  }, [itemsReportStatus, itemsReportData, isFetchingItemsReport]);

  console.log({ branches });

  return (
    <Box gap={2} p={{ xs: 2, sm: "8px 32px 32px 32px" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        gap={2}
        flexWrap={"wrap"}
        mb={3}
      >
        <Stack
          direction={"row"}
          spacing={"12px"}
          alignItems={"center"}
          color={"#101828"}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"60px"}
            height={"60px"}
            bgcolor={"#D0D5DD"}
            borderRadius={"16px"}
          >
            <img src={ReportsIcon} width={"32px"} alt="" />
          </Box>
          <Box>
            <Typography
              fontSize={"32px"}
              lineHeight={"38.4px"}
              color={"#101828"}
            >
              {t("thirdEyeReport")}
            </Typography>
            <Typography fontSize={"20px"} lineHeight={"24px"} color={"#98A2B3"}>
              {t("listOfChosenReports")}
            </Typography>
          </Box>
        </Stack>

        <Button
          variant="outlined"
          color="error"
          sx={{ height: "48px", border: "1px solid  #B32318" }}
          startIcon={<img src={youtubeIcon} alt="" width={"34px"} />}
          href="https://www.youtube.com/watch?v=uxQmpaBLMpc"
          target="_blank"
        >
          {T("viewHelp")}
        </Button>
      </Box>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}
      >
        <Typography color={"#475467"} fontWeight={600}>
          {t("availableReports")}
        </Typography>
        <Stack direction={"row"} gap={2}>
          <FormControl fullWidth size="small" sx={{ minWidth: 183 }}>
            <InputLabel id="demo-multiple-checkbox-label">
              {T("branches")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={branches}
              onChange={handleChangeBranches}
              input={<OutlinedInput label={T("branches")} />}
              renderValue={(selected) =>
                selected
                  .map(
                    (selectedId: number) =>
                      branchesData?.data.find(
                        (branch: any) => branch.id === +selectedId
                      )?.name
                  )
                  .join(", ")
              }
              // MenuProps={MenuProps}
            >
              {branchesData?.data.map((branch) => (
                <MenuItem
                  key={branch.id}
                  value={branch.id}
                  sx={{ padding: "0" }}
                >
                  <Checkbox
                    size="small"
                    checked={branches.indexOf(branch.id) > -1}
                  />
                  <ListItemText primary={branch.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ minWidth: 250 }}>
            <DateRangeInput
              startDate={
                dateRange.startDate ? moment(dateRange.startDate) : null
              } // moment.Moment | null;
              startDateId={`your_unique_start_date_id`} // moment.Moment | null;
              endDate={dateRange.endDate ? moment(dateRange.endDate) : null} // moment.Moment | null;
              endDateId={`your_unique_end_date_id`} // string;
              onDatesChange={(arg: {
                startDate: moment.Moment | null;
                endDate: moment.Moment | null;
              }) => {
                setDateRange({
                  startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                  endDate: arg.endDate?.format("yyyy-MM-DD") as string,
                });
              }}
            />
          </Box>
        </Stack>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={3}>
          <ReportBox
            name={t("OCReport")}
            onDownload={handleDownloadOCReport}
            isLoading={isFetching}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <ReportBox
            name={t("paymentReport")}
            onDownload={handleDownloadPaymentReport}
            isLoading={isFetchingPaymentReport}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <ReportBox
            name={t("inventoryReport")}
            onDownload={handleDownloadInventoryReport}
            isLoading={isFetchingInventoryReport}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <ReportBox
            name={t("purchaseReport")}
            onDownload={handleDownloadPurchaseReport}
            isLoading={isFetchingPurchaseReport}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <ReportBox
            name={t("taxReport")}
            onDownload={handleDownloadTaxReport}
            isLoading={isFetchingTaxReport}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <ReportBox
            name={t("forecastReport")}
            onDownload={handleDownloadForecastReport}
            isLoading={isFetchingForecastReport}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <ReportBox
            name={t("aggsReport")}
            onDownload={handleDownloadAggregatorsReport}
            isLoading={isFetchingAggregatorsReport}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <ReportBox
            name={t("PLReport")}
            onDownload={handleDownloadPLReport}
            isLoading={isFetchingPLReport}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <ReportBox
            name={t("supplierReport")}
            onDownload={handleDownloadSupplierReport}
            isLoading={isFetchingSupplierReport}
            options={suppliersData?.data}
            value={supplierId}
            setValue={setSupplierId}
            selectLabel={t("supplier")}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <ReportBox
            name={t("liabilitiesReport")}
            onDownload={handleDownloadAccountReport}
            isLoading={isFetchingAccountReport}
            options={accountsData?.data}
            value={accountId}
            setValue={setAccountId}
            selectLabel={t("liability")}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <ReportBox
            name={t("itemsReport")}
            onDownload={handleDownloadItemsReport}
            isLoading={isFetchingItemsReport}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportsPages;
