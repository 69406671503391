import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { useRef } from "react";
import ReactApexChart from "react-apexcharts";
import { formatLongNumbers, formatNumber } from "src/utils";
import { useTranslation } from "react-i18next";

const colors = [
  "#344054",
  "#4D4DEC",
  "#667085",
  "#F04438",
  "#73AF00",
  "#FF8B3D",
];

const Chart = ({ data }: { data: any }) => {
  const dataArr = Object.entries(data || {});
  console.log({ data, dataArr });
  const { t, i18n } = useTranslation("purchasing");
  const theme = useTheme();
  const matchesMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const chartRef: any = useRef();

  const daysArr: any = [];
  const valueArr: any = [];

  Object.entries(data || {})?.forEach(([day, value]) => {
    daysArr.push(moment(day).format(matchesMediumUp ? "D/M" : "DD / MM"));
    valueArr.push(value);
  });

  return (
    <ReactApexChart
      ref={chartRef}
      options={{
        noData: {
          text: t("noData"),
          style: {
            fontSize: matchesMediumUp ? "15px" : "12px",
          },
        },
        chart: {
          id: "line-chart",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: colors,
        xaxis: {
          categories: daysArr,
        },
        tooltip: {
          y: {
            formatter: (value: number): string =>
              formatNumber(+value)?.toString(),
          },
        },
        yaxis: {
          labels: {
            formatter: formatLongNumbers,
          },
        },
        markers: {
          size: 4, // Adjust the size of the marker
        },
        stroke: {
          width: 2,
          curve: "straight",
        },
        legend: {
          show: true,
          fontSize: "15px",
          markers: {
            width: 15,
            height: 15,
            strokeWidth: 0,
            radius: 2,
          },
          itemMargin: {
            horizontal: 0,
            vertical: matchesMediumUp ? 20 : 0,
          },
        },
      }}
      series={[
        {
          name: "",
          data: valueArr,
        },
      ]}
      type="line"
      height={350}
      lineWidth={10}
    />
  );
};

export default Chart;
