import {
  Box,
  Button,
  IconButton,
  Modal,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileItem from "src/shared/components/file-item";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useEffect } from "react";
import { downloadFileFromUrl, formatBytes } from "src/utils";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useUpdateAuditMutation } from "src/api/auditing/auditing";

interface IModel {
  open: boolean;
  handleClose: any;
  rowId: number;
  row: any;
  refetch: Function;
}
const AttachmentsSalesPopup = (props: IModel) => {
  const { open, handleClose, row, refetch } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t } = useTranslation("operationCost");
  const { t: generalT } = useTranslation("general");

  // APIS
  const {
    mutate: mutateUpdate,
    isPending: isPendingUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useUpdateAuditMutation();

  const handleConfirm = () => {
    mutateUpdate({ id: row.id, type: row.type, status: "confirmed" });
  };

  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("deletedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="attachments"
      aria-describedby="attachments-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: 547 },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {t("attachments")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        {false ? (
          <Stack gap={2}>
            <Skeleton variant="rectangular" width={"100%"} height={50} />
            <Skeleton variant="rectangular" width={"100%"} height={50} />
            <Skeleton variant="rectangular" width={"100%"} height={100} />
          </Stack>
        ) : (
          <>
            {row?.files.map((attachment: any) => {
              return (
                <FileItem
                  key={attachment.id}
                  name={`${attachment.file.name} .${attachment.file.extension}`}
                  info={formatBytes(attachment.file.size)}
                  isDeletable={false}
                  isDownloadable={true}
                  onDownload={() => {
                    downloadFileFromUrl(
                      attachment.file.url,
                      attachment.file.name
                    );
                  }}
                />
              );
            })}
            {row.status !== "confirmed" && (
              <Stack spacing={2} direction={"row"} mt={3}>
                <Button
                  variant="outlined"
                  color="tertiary"
                  fullWidth
                  onClick={handleClose}
                >
                  {generalT("cancel")}
                </Button>
                <LoadingButton
                  type="submit"
                  loading={isPendingUpdate}
                  variant="contained"
                  fullWidth
                  onClick={handleConfirm}
                >
                  {generalT("confirm")}
                </LoadingButton>
              </Stack>
            )}
          </>
        )}
      </Stack>
    </Modal>
  );
};

export default AttachmentsSalesPopup;
