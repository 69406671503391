import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useGetBranches, useGetPaymentMethods } from "src/api/generic";
import { useGetOCAccountsList } from "src/api/operation-cost/accounts";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import {
  useAddOCTransactionMutation,
  useUpdateOCTransactionMutation,
} from "src/api/operation-cost/transactions";
import { LoadingButton } from "@mui/lab";
import FilePondUploader from "src/shared/components/file-pond-uploader";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { FormModeTypes } from "src/types/generic";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import InfoBox from "src/shared/components/info-box";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import AddMoreIcon from "src/assets/svg-icons/settings/add_more.svg";
import { useGetAccountEvents } from "src/api/purchasing/purchasing";
import "./style.css";

const schema = yup.object().shape({
  branch_id: yup.number().required("Required"),
  account: yup
    .object()
    .shape({
      id: yup.number(),
    })
    .required("Required"),
  accountType: yup
    .string()
    .required("required")
    .oneOf(["fixed", "variable", "asset"] as const),
  amount: yup.string().required("Required"),
  include_tax: yup
    .number()
    .required("Required")
    .oneOf([1, 0] as const),
  tax: yup.string().required("Required"),
  invoice_number: yup.string(),
  note: yup.string(),
  cost_type: yup.string().when("accountType", {
    is: (value: string) => value === "fixed",
    then: (value) => value.required("Required"),
  }),
  payments: yup
    .array()
    .of(
      yup.object().shape({
        payment_method_id: yup.string(),
        amount: yup.string(),
      })
    )
    .required("Required"),
  is_payment_advanced: yup
    .number()
    .required("Required")
    .oneOf([1, 0] as const),
  purchase_event_id: yup.string().when("is_payment_advanced", {
    is: (is_payment_advanced: number) => is_payment_advanced === 1,
    then: (value) => value.required("Required"),
  }),
  purchase_event_amount: yup.string().when("is_payment_advanced", {
    is: (is_payment_advanced: number) => is_payment_advanced === 1,
    then: (value) => value.required("Required"),
  }),
  // asset account
  deprecation_percentage: yup.string().when("accountType", {
    is: "asset",
    then: (value) =>
      value
        .required("Required")
        .test(
          "min-value",
          "Must be greater than 0",
          (value) => parseFloat(value) > 0
        )
        .test("max-value", "Max is 100", (value) => parseFloat(value) <= 100),
  }),

  // new
  invoice_date: yup.string().required("required"),
  due_date: yup.string().required("required"),
  from_date: yup
    .string()
    .when("accountType", {
      is: (value: string) => value === "fixed",
      then: (value) => value.required("Required"),
    })
    .when("accountType", {
      is: (value: string) => value === "asset",
      then: (value) =>
        value
          .required("Required")
          .test(
            "is-greater-than-or-equal-due-date",
            "fromDateMustNotBeforeDueDate",
            function (value) {
              const { due_date } = this.parent;
              return value >= due_date;
            }
          ),
    }),
  to_date: yup.string().when("accountType", {
    is: (value: string) => value === "fixed" || value === "asset",
    then: (value) =>
      value
        .required("Required")
        .test(
          "is-greater-than-or-equal",
          "toDateMustNotBeforeFromDate",
          function (value) {
            const { from_date } = this.parent;
            return value >= from_date;
          }
        ),
  }),
  remind_date: yup.string().when("accountType", {
    is: (value: string) => value === "fixed",
    then: (value) => value.required("Required"),
  }),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  handleClose: () => void;
  row?: any;
  refetch: Function;
}

const AddEditTransactionPopup = (props: IProps) => {
  const { open, handleClose, row, refetch } = props;
  const { t } = useTranslation("operationCost");
  const { t: generalT } = useTranslation("general");
  const { t: purchasingT } = useTranslation("purchasing");
  const mode: FormModeTypes = !!row ? "edit" : "add";
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [files, setFiles] = useState<any>([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  //RHF
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    reset,
  } = useForm<FormInputs>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      tax: "0",
      payments: [
        {
          payment_method_id: "",
          amount: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "payments",
    control,
  });

  //APIS
  const { data: branchesData } = useGetBranches();
  const { data, isLoading, status: getAccountsStatus } = useGetOCAccountsList();
  const { data: paymentsData } = useGetPaymentMethods({
    enabled: !!watch("branch_id"),
    branches: [watch("branch_id")],
  });
  const { mutate, status, isPending, error } = useAddOCTransactionMutation();
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
    isPending: isPendingUpdate,
  } = useUpdateOCTransactionMutation();
  const { data: accountEventsData, isFetching } = useGetAccountEvents(
    watch("account")?.id
  );
  const amountWithoutTax =
    +watch("include_tax") === 1
      ? +(+watch("amount") - +watch("tax")).toFixed(5)
      : +watch("amount");

  const amountWithTax =
    +watch("include_tax") === 1
      ? +(+watch("amount")).toFixed(5)
      : +(+watch("amount") + +watch("tax")).toFixed(5);

  let paymentsCredit = 0;
  watch("payments").forEach((pay) => {
    paymentsCredit += Number(pay.amount) || 0;
  });
  let suppEventCredit = Number(watch("purchase_event_amount")) || 0;
  const credit = +(amountWithTax - paymentsCredit - suppEventCredit) || 0;

  const handleAppendPayment = () => {
    append({
      payment_method_id: "",
      amount: "",
    });
  };

  const handleRemovePayment = (index: number) => {
    remove(index);
  };

  const onClose = () => {
    handleClose();
    reset();
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const purchaseEventCreditRemaining =
    accountEventsData?.data.find(
      (item: any) => item.id === Number(watch("purchase_event_id"))
    )?.remaining ?? 0;

  const totalPurchaseEventCreditRemaining =
    mode === "edit"
      ? purchaseEventCreditRemaining + +row.purchase_event_amount
      : purchaseEventCreditRemaining;

  const isReminderInvoice = !row?.date;

  const isAssetInvoice = row?.account.type === "asset";

  const isCopyOfDeprecationAssetInvoice = isAssetInvoice && !row?.due_date;

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    // if (
    //   +data.is_payment_advanced === 1 &&
    //   data.purchase_event_amount &&
    //   +data.purchase_event_amount > totalPurchaseEventCreditRemaining
    // ) {
    //   setNotifications([
    //     ...notifications,
    //     {
    //       type: "error",
    //       message: `${t("eventCredit")}: ${totalPurchaseEventCreditRemaining}`,
    //     },
    //   ]);
    //   return;
    // }

    if (mode === "edit" && row?.parent_invoice && !isAssetInvoice) {
      if (!moment(watch("from_date")).isAfter(row.parent_invoice.end_date)) {
        setNotifications([
          ...notifications,
          {
            type: "error",
            message: `${t("lastCostDisruptionWas")} ${
              row?.parent_invoice.date
            } ${generalT("to")} ${row?.parent_invoice.end_date}, ${t(
              "pickupAfterThisDate"
            )} ${row?.parent_invoice.end_date}`,
          },
        ]);
        return;
      }
    }

    console.log({ data });
    const {
      tax,
      amount,
      note,
      invoice_number,
      payments,
      cost_type,
      include_tax,
      branch_id,
      account,
      deprecation_percentage,
      accountType,
      is_payment_advanced,
      invoice_date,
      due_date,
      remind_date,
      from_date,
      to_date,
      purchase_event_id,
      purchase_event_amount,
    } = data;

    const dataToSend: any = {
      branch_id: branch_id,
      account_id: account.id,
      // amount: +include_tax === 1 ? +(+amount - +tax).toFixed(5) : +amount,
      amount: amountWithoutTax,
      tax: tax,
      ...(invoice_number && {
        invoice_number: +invoice_number,
      }),
      include_tax: include_tax,
      ...(note && { note: note }),
      is_payment_advanced: +is_payment_advanced,
      ...(accountType === "fixed" && {
        is_advanced: Number(cost_type),
      }),
      invoice_date: invoice_date,
      due_date: due_date,
      ...(accountType === "fixed" && {
        repeat_date: remind_date,
      }),
      ...((accountType === "fixed" || accountType === "asset") && {
        date: from_date,
        end_date: to_date,
      }),
      // asset account
      ...(accountType === "asset" && {
        deprecation_percentage: deprecation_percentage,
      }),
      ...(accountType === "asset" && {
        deprecation_amount:
          (amountWithoutTax * Number(watch("deprecation_percentage"))) / 100,
      }),
      // purchase event
      ...(+is_payment_advanced === 1 && {
        purchase_event_id: purchase_event_id,
      }),
      ...(+is_payment_advanced === 1 && {
        purchase_event_amount: purchase_event_amount,
      }),
    };
    console.log({ dataToSend });
    const formData = new FormData();
    // append data
    for (const key in dataToSend) {
      formData.append(key, dataToSend[key]);
    }
    // append files
    if (files.length > 0) {
      files.forEach((file: any, index: number) => {
        formData.append(`files[${index}]`, file);
      });
    }
    if (mode === "add") {
      // append payments array
      payments.forEach((payment, index: number) => {
        if (
          payment.payment_method_id &&
          payment.amount &&
          +payment?.amount > 0
        ) {
          formData.append(
            `payments[${index}][payment_method_id]`,
            payment.payment_method_id as string
          );
          formData.append(
            `payments[${index}][amount]`,
            payment.amount as string
          );
        }
      });
      mutate(formData);
    } else {
      mutateUpdate({ ...dataToSend, id: row.id, payments: payments });
    }
  };

  useEffect(() => {
    if (!!row) {
      console.log({ row });
      const selectedAccount = data?.data?.find(
        (account: any) => account.id === row.account.id
      );
      reset({
        branch_id: row.branch.id,
        account: selectedAccount,
        amount: row.include_tax ? row.total : row.amount,
        tax: row.tax ?? "",
        include_tax: row.include_tax ? 1 : 0,
        invoice_number: row.invoice_number || "",
        payments: row.payments.map((pay: any) => {
          return {
            payment_method_id: pay.payment_method?.id,
            amount: pay.total,
          };
        }),
        note: row.note || "",
        cost_type: row.is_advanced ? "1" : "0",
        invoice_date: row.invoice_date || "",
        due_date: row.due_date || "",
        from_date: row.date || "",
        to_date: row.end_date || "",
        remind_date: row.repeat_date || "",
        deprecation_percentage: row.deprecation_percentage || "",
        is_payment_advanced: row.is_payment_advanced ? 1 : 0,
        purchase_event_id: row.purchase_event?.id || "",
        purchase_event_amount: row.purchase_event_amount || "",
      });
      setValue(
        "accountType",
        selectedAccount?.type === "fixed"
          ? "fixed"
          : selectedAccount?.type === "asset"
          ? "asset"
          : "variable"
      );

      // Set isResetting to true while form is resetting
      setIsResetting(true);
    }
  }, [row, open, data]);

  useEffect(() => {
    if (!isResetting) {
      setValue("purchase_event_id", "");
      setValue("purchase_event_amount", "");
    }
  }, [watch("account"), watch("is_payment_advanced")]);

  // Reset the isResetting flag after the form is reset
  useEffect(() => {
    if (isResetting) {
      setIsResetting(false);
    }
  }, [isResetting]);

  useEffect(() => {
    console.log(watch("include_tax"));
    if (mode === "add") {
      if (+watch("include_tax") === 1) {
        setValue(
          "tax",
          `${(((+getValues("amount") || 0) / 1.15) * 0.15).toFixed(2)}`
        );
      } else if (+watch("include_tax") === 0) {
        setValue("tax", `${((+getValues("amount") || 0) * 0.15).toFixed(2)}`);
      }
    }
  }, [watch("include_tax"), watch("amount")]);

  useEffect(() => {
    if (!!watch("account")) {
      const selectedAccount = data?.data?.find(
        (account: any) => account.id === watch("account").id
      );
      console.log({ selectedAccount });
      setValue("accountType", selectedAccount?.type);
    }
  }, [watch("account"), data]);

  // useEffect(() => {
  //   if (accountEventsData && !!watch("purchase_event_id") && mode === "add") {
  //     let purchaseEventAmount =
  //       accountEventsData?.data.find(
  //         (item: any) => item.id === Number(watch("purchase_event_id"))
  //       )?.remaining ?? 0;
  //     setValue("purchase_event_amount", purchaseEventAmount);
  //     console.log({ credit });
  //   }
  // }, [watch("purchase_event_id"), accountEventsData]);

  //Add OC trans success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update OC trans success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  console.log({ errors }, getValues());
  console.log({ row });

  useEffect(() => {
    if (
      watch("accountType") === "fixed" ||
      (watch("accountType") === "asset" && !!watch("due_date"))
    ) {
      if (watch("cost_type") === "1") {
        // advance
        setValue("from_date", watch("due_date"));
        if (mode === "add") {
          setValue("to_date", "");
        }
      } else if (watch("cost_type") === "0") {
        // late
        const dateBefore = moment(watch("due_date"), "YYYY-MM-DD")
          .subtract(1, "days")
          .format("YYYY-MM-DD");

        setValue("to_date", dateBefore);
        if (mode === "add") {
          setValue("from_date", "");
        }
      }
      // set default remind date by due date
      if (mode === "add" && !watch("remind_date")) {
        setValue("remind_date", watch("due_date"));
      }
    }
  }, [watch("cost_type"), watch("due_date")]);

  useEffect(() => {
    if (
      watch("accountType") === "asset" &&
      !!watch("from_date") &&
      Number(watch("deprecation_percentage")) > 0
    ) {
      const numberOfDeprecationMonths = Math.ceil(
        100 / Number(watch("deprecation_percentage"))
      );
      const endDateOfDeprecation = moment(watch("from_date"), "YYYY-MM-DD")
        .add(numberOfDeprecationMonths, "months")
        .format("YYYY-MM-DD");
      console.log(
        { numberOfDeprecationMonths, endDateOfDeprecation },
        watch("from_date")
      );
      setValue("to_date", endDateOfDeprecation);
    }
  }, [watch("deprecation_percentage"), watch("from_date")]);

  if (isLoading) {
    return <></>;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: 600 },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {mode === "add" ? t("addNewCost") : t("editCost")}
          </Typography>
          <IconButton onClick={handleOpenConfirm} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {/* branch */}
            <FormControl fullWidth size="small" error={!!errors.branch_id}>
              <InputLabel id="demo-simple-select-label">
                {generalT("branch")}
              </InputLabel>
              <Controller
                name="branch_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={generalT("branch")}
                    disabled={mode === "edit"}
                  >
                    {branchesData?.data?.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.branch_id?.message}
              </FormHelperText>
            </FormControl>

            {/* Account */}
            <FormControl fullWidth size="small" error={!!errors.account}>
              <Controller
                name="account"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    size="small"
                    {...field}
                    options={data?.data?.filter(
                      (item: any) =>
                        (!item.has_invoices ||
                          item.type === "variable" ||
                          item.type === "fixed" ||
                          row?.account.id === item.id) &&
                        item.status
                    )}
                    getOptionLabel={(option: any) => option?.name}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        error={!!errors.account}
                        helperText={errors.account?.message}
                        label={t("selectAccount")}
                      />
                    )}
                    renderOption={(props, option: any, { selected }) => (
                      <li key={props.id} {...props}>
                        <Typography>{option.name}</Typography>
                      </li>
                    )}
                    onChange={(_, data) => {
                      field.onChange(data);
                    }}
                    disabled={mode === "edit"}
                  />
                )}
              />
            </FormControl>

            {/* Amount */}
            <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
              <Box sx={{ width: 220 }}>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      fullWidth
                      label={t("amount")}
                      size="small"
                      {...field}
                      error={!!errors.amount}
                      helperText={errors.amount?.message}
                      disabled={isAssetInvoice}
                    />
                  )}
                />
              </Box>
              <FormControl
                fullWidth
                size="small"
                error={!!errors.include_tax}
                sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
              >
                <Controller
                  name="include_tax"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      key={field.value}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            size="small"
                            icon={<CircleOutlinedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label={t("includeTax")}
                        sx={{ color: "#98A2B3" }}
                        disabled={isAssetInvoice}
                      />
                      <FormControlLabel
                        value="0"
                        control={
                          <Radio
                            size="small"
                            icon={<CircleOutlinedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label={t("excludeTax")}
                        sx={{ color: "#98A2B3" }}
                        disabled={isAssetInvoice}
                      />
                    </RadioGroup>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {errors.include_tax?.message}
                </FormHelperText>
              </FormControl>
            </Stack>
            {/* tax */}
            <Controller
              name="tax"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t("taxPutZero")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.tax}
                  helperText={errors.tax?.message}
                  disabled={isAssetInvoice}
                />
              )}
            />
            {watch("accountType") === "asset" && (
              <Stack direction={"row"} gap={2} alignItems={"center"}>
                <Controller
                  name="deprecation_percentage"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      label={t("monthlyDeprecationPercentage")}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.deprecation_percentage}
                      helperText={errors.deprecation_percentage?.message}
                      sx={{ flexBasis: "60%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      disabled={isAssetInvoice}
                    />
                  )}
                />
                <Typography>
                  <Typography component={"span"} color={"textSecondary"}>
                    Amount
                  </Typography>{" "}
                  :{" "}
                  {(
                    (amountWithoutTax *
                      Number(watch("deprecation_percentage"))) /
                    100
                  ).toFixed(2)}{" "}
                  SAR
                </Typography>
              </Stack>
            )}

            {/* invoice number */}
            <Controller
              name="invoice_number"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t("invoiceNumberOptional")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.invoice_number}
                  helperText={errors.invoice_number?.message}
                />
              )}
              disabled={isAssetInvoice}
            />

            {/* is advanced */}
            <Stack direction={"row"} alignItems={"center"} gap={3}>
              <Typography>{purchasingT("hasAdvancePayment")}?</Typography>
              <FormControl
                fullWidth
                size="small"
                error={!!errors.is_payment_advanced}
                sx={{
                  width: "fit-content",
                  ml: { xs: "auto", sm: "unset" },
                }}
              >
                <Controller
                  name="is_payment_advanced"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      key={field.value}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            size="small"
                            icon={<CircleOutlinedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label={generalT("yes")}
                        sx={{ color: "#98A2B3" }}
                        disabled={isAssetInvoice}
                      />
                      <FormControlLabel
                        value="0"
                        control={
                          <Radio
                            size="small"
                            icon={<CircleOutlinedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label={generalT("no")}
                        sx={{ color: "#98A2B3" }}
                        disabled={isAssetInvoice}
                      />
                    </RadioGroup>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {errors.is_payment_advanced?.message}
                </FormHelperText>
              </FormControl>
            </Stack>
            {/* Event */}
            {+watch("is_payment_advanced") === 1 && (
              <Stack gap={1}>
                <FormControl
                  fullWidth
                  size="small"
                  error={!!errors.purchase_event_id}
                >
                  <InputLabel id="demo-simple-select-label">
                    {purchasingT("selectTypeOfPrepaid")}
                  </InputLabel>
                  <Controller
                    name="purchase_event_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        key={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={purchasingT("selectTypeOfPrepaid")}
                        disabled={isAssetInvoice}
                      >
                        {accountEventsData?.data
                          // ?.filter((event:any) => pay.status)
                          .map(({ id, reference }: any) => {
                            return (
                              <MenuItem key={id} value={`${id}`}>
                                {reference}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {errors.purchase_event_id?.message}
                  </FormHelperText>
                </FormControl>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <Controller
                    name="purchase_event_amount"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="number"
                        label={t("amount")}
                        fullWidth
                        size="small"
                        {...field}
                        error={!!errors.purchase_event_amount}
                        helperText={errors.purchase_event_amount?.message}
                        InputLabelProps={{
                          shrink: field.value !== undefined ? true : false,
                        }}
                        disabled={isAssetInvoice}
                      />
                    )}
                  />
                  <Typography whiteSpace={"nowrap"}>
                    {t("eventCredit")}: {totalPurchaseEventCreditRemaining}
                  </Typography>
                </Stack>
              </Stack>
            )}

            <>
              {/* payments */}
              {fields.map((paymentField, index) => {
                return (
                  <Stack key={paymentField.id} direction={"row"} gap={1}>
                    <FormControl
                      fullWidth
                      size="small"
                      error={
                        errors.payments &&
                        !!errors.payments[index]?.payment_method_id
                      }
                    >
                      <InputLabel id="demo-simple-select-label">
                        {t("paymentMethodOptional")}
                      </InputLabel>
                      <Controller
                        name={`payments.${index}.payment_method_id`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            key={field.value}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={t("paymentMethodOptional")}
                            disabled={mode === "edit"}
                          >
                            {paymentsData?.data
                              ?.filter((pay) => pay.status)
                              .map(({ id, name }) => {
                                return (
                                  <MenuItem key={id} value={`${id}`}>
                                    {name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                      />
                      <FormHelperText id="my-helper-text">
                        {errors.payments &&
                          errors.payments[index]?.payment_method_id?.message}
                      </FormHelperText>
                    </FormControl>

                    <Controller
                      name={`payments.${index}.amount`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          label={t("amount")}
                          variant="outlined"
                          {...field}
                          error={
                            errors.payments && !!errors.payments[index]?.amount
                          }
                          helperText={
                            errors.payments &&
                            errors.payments[index]?.amount?.message
                          }
                          fullWidth
                          size="small"
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          disabled={mode === "edit"}
                        />
                      )}
                    />

                    {mode === "add" && (
                      <IconButton onClick={() => handleRemovePayment(index)}>
                        <img src={DeleteIcon} alt="" />
                      </IconButton>
                    )}
                  </Stack>
                );
              })}
            </>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {mode === "add" && (
                <Button
                  variant="text"
                  size="small"
                  startIcon={<img src={AddMoreIcon} alt="" />}
                  onClick={handleAppendPayment}
                  sx={{ alignSelf: "flex-start" }}
                >
                  {purchasingT("addAnotherMethod")}
                </Button>
              )}

              <Typography>
                {t("credit")}: {credit} {generalT("sar")}
              </Typography>
            </Stack>
          </Stack>

          {mode === "edit" && row?.parent_invoice && !isAssetInvoice ? (
            <Box mt={2}>
              <InfoBox
                text={`${t("lastCostDisruptionWas")} ${
                  row?.parent_invoice.date
                } ${generalT("to")} ${row?.parent_invoice.end_date}, ${t(
                  "pickupAfterThisDate"
                )} ${row?.parent_invoice.end_date}`}
              />
            </Box>
          ) : null}

          <Stack direction={"row"} gap={2} mt={2}>
            <Controller
              name="invoice_date"
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  label={`${t("invoiceDate")}`}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.invoice_date}
                  helperText={errors.invoice_date?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={isAssetInvoice}
                />
              )}
            />
            <Controller
              name="due_date"
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  label={`${t("dueDate")} (${t("whenBillDue")})`}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.due_date}
                  helperText={errors.due_date?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={isAssetInvoice}
                />
              )}
            />
          </Stack>

          {watch("accountType") === "fixed" ||
          watch("accountType") === "asset" ? (
            <Stack mt={2} gap={2}>
              <Typography fontSize={"14px"} fontWeight={600}>
                {watch("accountType") === "fixed"
                  ? t("costDistributionPeriod")
                  : t("deprecationPeriod")}
              </Typography>

              {watch("accountType") === "fixed" && (
                <>
                  <FormControl
                    fullWidth
                    size="small"
                    error={!!errors.cost_type}
                    sx={{
                      width: "fit-content",
                      ml: { xs: "auto", sm: "unset" },
                    }}
                  >
                    <Controller
                      name="cost_type"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          key={field.value}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="1"
                            control={
                              <Radio
                                size="small"
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                            label={
                              <Typography fontSize={"14px"}>
                                {t("dueInAdvance")}
                              </Typography>
                            }
                            sx={{ color: "#98A2B3" }}
                            disabled={isAssetInvoice}
                          />
                          <FormControlLabel
                            value="0"
                            control={
                              <Radio
                                size="small"
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                            label={
                              <Typography fontSize={"14px"}>
                                {t("dueInArrears")}
                              </Typography>
                            }
                            sx={{ color: "#98A2B3" }}
                            disabled={isAssetInvoice}
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                  <FormHelperText
                    error
                    id="my-helper-text"
                    sx={{ mt: -1, px: 2 }}
                  >
                    {errors.cost_type?.message}
                  </FormHelperText>
                </>
              )}

              <Stack direction={"row"} gap={2}>
                <Controller
                  name="from_date"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="date"
                      label={`${t("fromDate")}`}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.from_date}
                      helperText={t(`${errors.from_date?.message || ""}`)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={watch("cost_type") === "1" || isAssetInvoice}
                    />
                  )}
                />
                <Controller
                  name="to_date"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="date"
                      label={`${t("toDate")}`}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.to_date}
                      helperText={t(`${errors.to_date?.message || ""}`)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={
                        isAssetInvoice ||
                        watch("accountType") === "asset" ||
                        (watch("accountType") === "fixed" &&
                          watch("cost_type") === "0")
                      }
                    />
                  )}
                />
              </Stack>

              {watch("accountType") === "fixed" && (
                <Stack direction={"row"} gap={2} alignItems={"center"}>
                  <Typography
                    color={"textSecondary"}
                    fontSize={"14px"}
                    fontWeight={600}
                    whiteSpace={"nowrap"}
                  >
                    {t("remindMeAt")}
                  </Typography>
                  <Controller
                    name="remind_date"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="date"
                        label={`${t("date")}`}
                        fullWidth
                        size="small"
                        {...field}
                        error={!!errors.remind_date}
                        helperText={errors.remind_date?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={isAssetInvoice}
                      />
                    )}
                  />
                </Stack>
              )}

              {watch("accountType") === "fixed" && watch("remind_date") && (
                <InfoBox
                  text={`${t("invoiceWillAutoRemindAt")} ${moment(
                    watch("remind_date"),
                    "YYYY-MM-DD"
                  ).format("MM/DD/YYYY")}`}
                />
              )}

              {watch("accountType") === "asset" && watch("to_date") && (
                <InfoBox
                  text={`${t(
                    "InvoiceWillAutoDeprecationMonthlyUntil"
                  )} ${moment(watch("to_date"), "YYYY-MM-DD").format(
                    "MM/DD/YYYY"
                  )}`}
                />
              )}
            </Stack>
          ) : null}

          {/* note */}
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <TextField
                label={t("notesOptional")}
                fullWidth
                size="small"
                multiline
                rows={2}
                maxRows={3}
                {...field}
                error={!!errors.note}
                helperText={errors.note?.message}
                sx={{ mt: 2 }}
              />
            )}
          />

          {/* files */}
          {mode === "add" && (
            <Box width={"100%"} mt={2}>
              <FilePondUploader
                onUpload={(e) => setFiles(e)}
                maxFiles={3}
                maxFileSize={3}
                acceptedFileTypes={[
                  "image/*",
                  "application/pdf",
                  "text/csv",
                  "application/vnd.ms-excel",
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                ]}
              />
            </Box>
          )}
          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={onClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isPending || isPendingUpdate}
            >
              {mode === "add" ? t("addNewCost") : generalT("save")}
            </LoadingButton>
          </Stack>
        </form>
        {/* confirm close popup */}
        <ConfirmPopup
          open={openConfirm}
          handleClose={handleCloseConfirm}
          handleConfirm={handleClose}
          title={generalT("discard")}
          subtitle={generalT("areYouSureToDiscardChanges")}
          confirmBtnText={generalT("confirm")}
        />
      </Stack>
    </Modal>
  );
};

export default AddEditTransactionPopup;
