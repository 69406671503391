import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BorderLinearProgress } from "src/pages/add-sales/border-linear-progress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FileUploader from "src/shared/components/file-uploader";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import logo from "src/assets/svg-icons/logo.svg";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import YoutubeViewerBox from "src/pages/add-sales/youtube-viewer-box";
import { useGetBranches } from "src/api/generic";
import BoxSelect from "src/shared/components/box-select";
import ManualIcon from "src/assets/svg-icons/manual.svg";
import FoodicsIcon from "src/assets/svg-icons/foodics.svg";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import moment from "moment";
import DateRangeInput from "src/shared/components/date-range";
import * as yup from "yup";
import { useGetAggregatorsList } from "src/api/aggreagators";
import { useImportAggsMatchingMutation } from "src/api/matching/aggregators";
import { convertObjectToFormData } from "src/utils";
import InfoBox from "src/shared/components/info-box";

export const steps = [
  {
    id: "aggs_info",
    fields: [
      "branch_id",
      "aggregator_id",
      "dateRange",
      "start_time",
      "end_time",
    ],
  },
  {
    id: "files",
    fields: ["foodics_file", "agg_file"],
  },
];

interface IProps {}

export const schema = Yup.object().shape({
  type: Yup.string()
    .required("Required")
    .oneOf(["excel", "foodics"] as const),
  branch_id: Yup.string().required("Required"),
  aggregator_id: Yup.string().required("Required"),
  start_time: Yup.string().required("Required"),
  end_time: Yup.string().required("Required"),
  dateRange: yup.object().shape({
    start_date: yup.string().required("Required"),
    end_date: yup
      .string()
      .required("Required")
      .test(
        "date-range",
        "Maximum adding sales days is 31 days for each sales journey",
        function (endDate) {
          const startDate = this.parent.start_date;
          if (!startDate || !endDate) return true; // Allow empty values, leave validation to required()

          const startDateObj = new Date(startDate);
          const endDateObj = new Date(endDate);
          const differenceInTime =
            endDateObj.getTime() - startDateObj.getTime();
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);

          return differenceInDays <= 31;
        }
      ),
  }),

  foodics_file: Yup.string().required("Required"),
  agg_file: Yup.string().required("Required"),
});

export interface FormInputs extends Yup.InferType<typeof schema> {}

type ITypes = "foodics" | "excel";

const AddAggregatorTransactions: React.FC<IProps> = ({}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("matching");
  const { t: generalT } = useTranslation("general");
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [currentStep, setCurrentStep] = useState(0);
  const [foodicsFile, setFoodicsFile] = useState<any>();
  const [aggFile, setAggFile] = useState<any>();
  const [foodicsFileData, setFoodicsFileData] = useState<any[]>([]);
  const [aggFileData, setAggFileData] = useState<any[]>([]);
  const [type, setType] = useState<ITypes>("foodics");

  // React hook form
  const {
    formState: { errors },
    setValue,
    control,
    getValues,
    trigger,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      type: "foodics",
    },
  });

  // APIs
  const { data: branchesData } = useGetBranches();
  const { data: aggregatorsData } = useGetAggregatorsList();
  const { mutate, isPending, status, error } = useImportAggsMatchingMutation();

  const goBack = () => {
    setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
  };

  const goNext = async () => {
    let stepFields = steps[currentStep].fields;
    const validStep = await trigger(stepFields as any[], { shouldFocus: true });
    //not valid from
    if (!validStep) {
      return;
    }

    const data = getValues();

    // go next step
    if (currentStep === 0) {
      setCurrentStep((prev) => prev + 1);
    } else {
      if (foodicsFile && aggFile) {
        const formDataToSend = convertObjectToFormData({
          type: "aggregator",
          import_file_type: "foodics",
          aggregator: "jahez",
          branch_id: data.branch_id,
          start_date: data.dateRange.start_date,
          end_date: data.dateRange.end_date,
          start_time: data.start_time,
          end_time: data.end_time,
        });
        formDataToSend.append("foodics_file", foodicsFile);
        formDataToSend.append("import_file", aggFile);

        mutate(formDataToSend);
      }
    }
  };

  const handleFoodicsFileUpload = (data: any) => {
    console.log({ data });
    setFoodicsFile(data || undefined);
    if (data) {
      Papa.parse(data, {
        header: true,
        complete: (results: any) => {
          console.log({ results });
          setFoodicsFileData(results.data);
        },
        skipEmptyLines: true,
      });
    }
  };

  const handleAggFileUpload = (data: any) => {
    console.log({ data });
    setAggFile(data || undefined);
    if (data) {
      Papa.parse(data, {
        header: true,
        complete: (results: any) => {
          console.log({ results });
          setAggFileData(results.data);
        },
        skipEmptyLines: true,
      });
    }
  };

  useEffect(() => {
    setFoodicsFile(undefined);
  }, [type]);

  console.log({ foodicsFile, aggFile }, getValues);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
      navigate("/matching");
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Box>
      <Box px={{ xs: 3, md: 4 }} pt={4} pb={{ xs: 1, md: 0 }}>
        <img src={logo} alt="" />
        <IconButton
          sx={{ position: "absolute", top: "30px", right: "30px" }}
          onClick={() => {
            navigate("/matching");
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display={"flex"} justifyContent={"center"} pb={"150px"}>
        <Box maxWidth={"736px"} width={"100%"} px={{ xs: 3, md: 0 }}>
          {/* Step 1 */}
          <Box sx={{ display: currentStep === 0 ? "block" : "none" }}>
            <Typography fontSize={"26px"} fontWeight={600}>
              {t("addAggTransactions")}
            </Typography>
            <Typography color={"textSecondary"}>{t("letKnowYou")}</Typography>
            <YoutubeViewerBox videoId="djvKcUwSmqw" />

            {/* select Branches */}
            <FormControl
              fullWidth
              size="small"
              error={!!errors.branch_id}
              sx={{ my: "10px" }}
            >
              <InputLabel id="demo-simple-select-label">
                {t("branch")}
              </InputLabel>
              <Controller
                name="branch_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t("branch")}
                  >
                    {branchesData?.data?.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.branch_id?.message}
              </FormHelperText>
            </FormControl>

            {/* date range */}
            <Box width={"100%"}>
              <Controller
                name="dateRange"
                control={control}
                defaultValue={{
                  start_date: "",
                  end_date: "",
                }}
                render={({ field }) => (
                  <DateRangeInput
                    startDate={
                      field.value.start_date
                        ? moment(field.value.start_date)
                        : null
                    } // moment.Moment | null;
                    startDateId={`your_unique_start_date_id`} // moment.Moment | null;
                    endDate={
                      field.value.end_date ? moment(field.value.end_date) : null
                    } // moment.Moment | null;
                    endDateId={`your_unique_end_date_id`} // string;
                    onDatesChange={(arg: {
                      startDate: moment.Moment | null;
                      endDate: moment.Moment | null;
                    }) => {
                      setValue("dateRange", {
                        start_date: arg.startDate?.format(
                          "yyyy-MM-DD"
                        ) as string,
                        end_date: arg.endDate?.format("yyyy-MM-DD") as string,
                      });
                    }}
                  />
                )}
              />
              <FormHelperText error id="my-helper-text" sx={{ px: 2 }}>
                {errors.dateRange?.start_date?.message ||
                  errors.dateRange?.end_date?.message}
              </FormHelperText>
            </Box>

            <Stack direction={"row"} gap={3} my={3}>
              <Controller
                name={`start_time`}
                control={control}
                render={({ field }) => (
                  <TextField
                    type="time"
                    variant="outlined"
                    {...field}
                    label={generalT("from")}
                    error={!!errors.start_time}
                    helperText={errors.start_time?.message}
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
              <Controller
                name={`end_time`}
                control={control}
                render={({ field }) => (
                  <TextField
                    type="time"
                    variant="outlined"
                    label={generalT("to")}
                    {...field}
                    error={!!errors.end_time}
                    helperText={errors.end_time?.message}
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Stack>

            <Box>
              <InfoBox text={t("dateRangeAndTimeOfPOSSystem")} />
            </Box>

            {/* select aggregator */}
            <FormControl
              fullWidth
              size="small"
              error={!!errors.aggregator_id}
              sx={{ my: "10px" }}
            >
              <InputLabel id="demo-simple-select-label">
                {generalT("aggregator")}
              </InputLabel>
              <Controller
                name="aggregator_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={generalT("aggregator")}
                  >
                    <MenuItem value={"jahez"}>{t("jahez")}</MenuItem>
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.aggregator_id?.message}
              </FormHelperText>
            </FormControl>
          </Box>
          {/* summary */}
          {currentStep === 1 && (
            <Box>
              <Typography fontSize={"26px"} fontWeight={600}>
                {t("addAggTransactions")}
              </Typography>
              <Typography color={"textSecondary"}>{t("letKnowYou")}</Typography>
              <YoutubeViewerBox videoId="djvKcUwSmqw" />
              {/* type */}
              <Box mb={"30px"}>
                <BoxSelect
                  label={""}
                  options={[
                    {
                      label: generalT("foodics"),
                      value: "foodics",
                      icon: <img src={FoodicsIcon} alt="" />,
                    },
                    // {
                    //   label: generalT("excel"),
                    //   value: "excel",
                    //   icon: <img src={ManualIcon} alt="" />,
                    //   disabled: true,
                    // },
                  ]}
                  value={type}
                  onChange={(value) => setType(value as ITypes)}
                />
              </Box>
              {type === "excel" ? (
                <></>
              ) : // <Box mb={1.5}>
              //   <Typography mb={1}>
              //     {t("toUploadYourSalesFromExcel")}
              //   </Typography>
              //   <Stack direction={"row"} alignItems={"center"} gap={0.8}>
              //     <Box
              //       bgcolor={"#000"}
              //       width={"4px"}
              //       height={"4px"}
              //       borderRadius={"50%"}
              //     />
              //     <Typography>{t("downloadOur")}</Typography>
              //     <Link
              //       color={"#1A1AE6"}
              //       sx={{ cursor: "pointer" }}
              //       // onClick={handleDownloadTemplate}
              //     >
              //       {t("template")}.
              //     </Link>
              //   </Stack>
              //   <Stack direction={"row"} alignItems={"center"} gap={0.8}>
              //     <Box
              //       bgcolor={"#000"}
              //       width={"4px"}
              //       height={"4px"}
              //       borderRadius={"50%"}
              //     />
              //     <Typography>{t("fulfillRequiredColumns")}</Typography>
              //   </Stack>
              //   <Stack direction={"row"} alignItems={"center"} gap={0.8}>
              //     <Box
              //       bgcolor={"#000"}
              //       width={"4px"}
              //       height={"4px"}
              //       borderRadius={"50%"}
              //     />
              //     <Typography>{t("uploadUpdatedFileHere")}</Typography>
              //   </Stack>
              // </Box>
              null}

              {/* Foodics File */}
              <FileUploader
                label={t("goToFoodicsFile")}
                onFileUpload={(data) => handleFoodicsFileUpload(data)}
                onFileDelete={() => setFoodicsFile(undefined)}
                id="foodics_file"
                name="foodics_file"
                control={control}
                error={!!errors.foodics_file}
                helperText={errors.foodics_file?.message}
                isLoading={false}
                value={foodicsFile}
              />

              {/* Agg File */}
              <Box pt={2}>
                <FileUploader
                  label={t("goToJahezFile")}
                  onFileUpload={(data) => handleAggFileUpload(data)}
                  onFileDelete={() => setAggFile(undefined)}
                  id="agg_file"
                  name="agg_file"
                  control={control}
                  error={!!errors.agg_file}
                  helperText={errors.agg_file?.message}
                  isLoading={false}
                  value={aggFile}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {/* progress bar */}
      <Box
        bgcolor={"white"}
        zIndex={2}
        width={"100%"}
        position={"fixed"}
        sx={{ bottom: "0" }}
        boxShadow={"0 -8px 50px -5px rgba(50, 50, 50, 0.1)"}
      >
        <BorderLinearProgress
          variant="determinate"
          value={((currentStep + 1) / steps.length) * 100}
        />
        <Stack
          direction={"row"}
          justifyContent={"space-around"}
          m={3}
          gap={"10px"}
        >
          {currentStep > 0 ? (
            <Button onClick={goBack} variant="outlined" color="tertiary">
              {t("back")}
            </Button>
          ) : (
            <Box />
          )}

          <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
            <LoadingButton
              variant="contained"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    height: "15px",
                    transform: i18n.language === "ar" ? "scaleX(-1)" : "",
                  }}
                />
              }
              sx={{ display: "flex" }}
              onClick={goNext}
              loading={isPending}
            >
              {currentStep < steps.length - 1 ? t("next") : t("finish")}
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default AddAggregatorTransactions;
