import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SettingIcon from "src/assets/svg-icons/side-bar/settings.svg";
import RightArrow from "src/assets/svg-icons/aggregators/right_arrow.svg";
import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ReportsIcon from "src/assets/svg-icons/side-bar/reports.svg";
import DashboardIcon from "src/assets/svg-icons/side-bar/dashboard.svg";
import AggregatorsIcon from "src/assets/svg-icons/side-bar/aggregators.svg";
import OperationIcon from "src/assets/svg-icons/side-bar/operation.svg";

import {
  useAddRoleMutation,
  useGetPermissions,
  useGetRoleById,
  useUpdateRoleMutation,
} from "src/api/settings/roles";
import { ChangeEvent, useEffect, useState } from "react";
import { IPermissionsData } from "src/types/settings";
import { LoadingButton } from "@mui/lab";
import { FormModeTypes } from "src/types/generic";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";

const icons: { [key: string]: string } = {
  Dashboard: DashboardIcon,
  Aggregators: AggregatorsIcon,
  "Third Eye Report": ReportsIcon,
  "Operation Cost": OperationIcon,
  Settings: SettingIcon,
};

const schema = yup.object().shape({
  role_name: yup.string().required("Role name is required"),
});

interface FormInputs {
  role_name: string;
}

const AddEditRole = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [permissionsList, setPermissionsList] = useState<IPermissionsData>([]);
  const [mode, setMode] = useState<FormModeTypes>(!!params.id ? "edit" : "add");
  const { t } = useTranslation("settings");
  const { t: generalT } = useTranslation("general");
  
  // APIS
  const { data: permissionsData, isLoading, refetch } = useGetPermissions();
  const { mutate, isPending, status, error } = useAddRoleMutation();
  const {
    mutate: mutateUpdate,
    isPending: isPendingUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useUpdateRoleMutation();
  const { data: roleData } = useGetRoleById({
    enabled: !!params.id,
    id: params.id as string,
  });

  // react hook form
  const { control, handleSubmit, formState, reset } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const navigateToSettings = () => {
    navigate("/settings");
  };

  const navigateToSettingsRoles = () => {
    navigate("/settings/roles");
  };

  const handleCheckboxChange = (
    categoryIndex: number,
    subgroupIndex: number,
    permissionIndex: number
  ) => {
    setPermissionsList((prevPermissionsList) => {
      const updatedPermissionsData = [...prevPermissionsList];
      updatedPermissionsData[categoryIndex].subgroups[
        subgroupIndex
      ].permissions[permissionIndex].checked =
        !updatedPermissionsData[categoryIndex].subgroups[subgroupIndex]
          .permissions[permissionIndex].checked;
      return updatedPermissionsData;
    });
  };

  const getCheckedPermissionIds = () => {
    const checkedPermissionIds: number[] = [];
    permissionsList.forEach((category) => {
      category.subgroups.forEach((subgroup) => {
        subgroup.permissions.forEach((permission) => {
          if (permission.checked) {
            checkedPermissionIds.push(permission.id);
          }
        });
      });
    });
    return checkedPermissionIds;
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const checkedPermissionIds = getCheckedPermissionIds();
    if (mode === "add") {
      mutate({
        role: {
          name: data.role_name,
        },
        permissions: checkedPermissionIds,
      });
    } else {
      mutateUpdate({
        id: params.id as string,
        role: {
          name: data.role_name,
        },
        permissions: checkedPermissionIds,
      });
    }
  };

  useEffect(() => {
    if (permissionsData) {
      const permissionsDataWithCheckedKey: IPermissionsData =
        permissionsData.data.permissions.map((category) => ({
          ...category,
          subgroups: category.subgroups.map((subgroup) => ({
            ...subgroup,
            permissions: subgroup.permissions.map((permission) => ({
              ...permission,
              checked:
                roleData?.data.permissions.includes(permission.id) || false, // Default value for checked property
            })),
          })),
        }));
      setPermissionsList(permissionsDataWithCheckedKey);
    }
    if (roleData) {
      reset({ role_name: roleData.data.name });
    }
  }, [permissionsData, roleData]);

  //Add agg cost/fee success and error handling
  useEffect(() => {
    if (status === "success") {
      navigateToSettingsRoles();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update agg cost/fee success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Stack gap={3} p={{ xs: 2, sm: 4 }}>
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        color={"#101828"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={SettingIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {t("settings")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />

      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToSettings}
          sx={{ cursor: "pointer" }}
        >
          {t("settings")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToSettingsRoles}
          sx={{ cursor: "pointer" }}
        >
          {t("roles")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Box borderRadius={"4px"} bgcolor={"#EAECF0"} p={"4px 8px"}>
          <Typography color={"#344054"} fontWeight={600} lineHeight={"19.2px"}>
            {t("addNewRole")}
          </Typography>
        </Box>
      </Stack>
      <Stack bgcolor={"#F9FAFB"} borderRadius={1} p={3}>
        <Typography
          color={"#475467"}
          fontSize={16}
          fontWeight={600}
          lineHeight={"19.2px"}
          mb={2}
        >
         {t("roleName")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={"34px"} sx={{ width: { xs: "100%", md: 276 } }}>
            <Controller
              name="role_name"
              control={control}
              render={({ field }) => (
                <TextField
                  autoFocus
                  fullWidth
                  size="small"
                  {...field}
                  error={!!formState.errors.role_name}
                  helperText={formState.errors.role_name?.message}
                  sx={{ bgcolor: "#FFFFFF" }}
                />
              )}
            />
          </Box>

          <Typography
            color={"#475467"}
            fontSize={16}
            fontWeight={600}
            lineHeight={"19.2px"}
            mb={2}
          >
            {t("rolePermissions")}
          </Typography>
          {/* start permissions*/}
          {permissionsList.map((module, moduleIndex) => {
            return (
              <Box key={module.name} mb={3}>
                <Stack spacing={"10px"} direction={"row"} mb={2}>
                  <img src={icons[module.name]} alt="" width={"24px"} />
                  <Typography
                    fontSize={20}
                    fontWeight={600}
                    lineHeight={"24px"}
                  >
                    {module.name}
                  </Typography>
                </Stack>

                <Grid
                  container
                  // alignItems={"baseline"}
                  p={"20px"}
                  borderRadius={1}
                  bgcolor={"#F2F4F7"}
                >
                  {module.subgroups.map((subgroup, subgroupIndex) => {
                    return (
                      <>
                        <Grid
                          item
                          xs={12}
                          lg={2.5}
                          color={"#667085"}
                          fontSize={"16px"}
                          height={"100%"}
                        >
                          {subgroup.name}
                        </Grid>
                        <Grid item container xs={12} lg={9.5} rowGap={4}>
                          {subgroup.permissions.map(
                            (permission, permissionIndex) => {
                              return (
                                <Grid item xs={12} lg={4} key={permission.id}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          sx={{ paddingY: "0" }}
                                          checked={permission.checked}
                                          onChange={() =>
                                            handleCheckboxChange(
                                              moduleIndex,
                                              subgroupIndex,
                                              permissionIndex
                                            )
                                          }
                                        />
                                      }
                                      label={permission.name}
                                    />
                                  </FormGroup>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>

                        {/* divider */}
                        {subgroupIndex < module.subgroups.length - 1 && (
                          <Box
                            width={"100%"}
                            height={"1px"}
                            bgcolor={"#D0D5DD"}
                            my={3}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
              </Box>
            );
          })}
          {/* end permissions*/}
          <Box display={"flex"} justifyContent={"end"} mt={4}>
            <Stack direction={"row"} spacing={1} width={489}>
              <Button
                variant="outlined"
                color="tertiary"
                fullWidth
                onClick={navigateToSettingsRoles}
              >
                {generalT("cancel")}
              </Button>
              <LoadingButton
                loading={isPending || isPendingUpdate}
                variant="contained"
                type="submit"
                fullWidth
              >
                {generalT("save")}
              </LoadingButton>
            </Stack>
          </Box>
        </form>
      </Stack>
    </Stack>
  );
};

export default AddEditRole;
