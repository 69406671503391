import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { useGetBranches } from "src/api/generic";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TablePagination,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDownloadLevelsList,
  useGetLevelsList,
} from "src/api/inventory/levels";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import { downloadFile } from "src/utils";

const LevelTable = () => {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [selectedBranch, setSelectedBranch] = useState<number>();
  const { t } = useTranslation("inventory");

  const tableHeadText = [
    t("itemName"),
    t("sku"),
    t("ingredientsUnit"),
    t("type"),
    t("shelfLife"),
    t("itemQnty"),
    t("unitCost"),
    t("totalCost"),
  ];

  // APIS
  const { data, isLoading, refetch } = useGetLevelsList({
    page: pageNumber + 1,
    branch_id: selectedBranch as number,
  });
  const { data: branchesData } = useGetBranches();
  const {
    data: downloadedData,
    refetch: refetchDownload,
    isFetching: isFetchingDownload,
    status,
  } = useDownloadLevelsList({
    branch_id: selectedBranch as number,
  });

  const emptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleDownload = () => {
    refetchDownload();
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [pageNumber]);

  useEffect(() => {
    if (!!branchesData) {
      setSelectedBranch(branchesData.data[0].id);
    }
  }, [branchesData]);

  useEffect(() => {
    if (selectedBranch) {
      refetch();
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (status === "success" && !!downloadedData) {
      const utf8CSVData = `\uFEFF${downloadedData}`;
      downloadFile(utf8CSVData, "text/csv", "levels");
    }
  }, [isFetchingDownload]);

  return (
    <Box>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        spacing={2}
        mb={2}
      >
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">
              {t("branches")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={t("branches")}
              size="small"
              value={`${selectedBranch}`}
              onChange={(e) => setSelectedBranch(+e.target.value)}
            >
              {/* <MenuItem value={10}>All</MenuItem> */}
              {branchesData?.data.map((branch) => (
                <MenuItem key={branch.id} value={branch.id}>
                  {branch.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {/* <Button
          color="tertiary"
          variant="outlined"
          size="small"
          endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
          sx={{ height: "40px" }}
        >
          {t("filter")}
        </Button> */}

        <Box sx={{ minWidth: 114 }}>
          <LoadingButton
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
            loading={isFetchingDownload}
            onClick={handleDownload}
          >
            {t("download")}
          </LoadingButton>
        </Box>
      </Stack>
      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "18px",
                    color: "#475467",
                    bgcolor: "#F9FAFB",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading || !branchesData ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={8} />
          ) : (
            <TableBody>
              {data?.data.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.sku}</TableCell>
                  <TableCell align="center">{row.unit?.name}</TableCell>
                  <TableCell align="center">{row.type}</TableCell>
                  <TableCell align="center">{row.shelf_life}</TableCell>
                  <TableCell align="center">{row.quantity}</TableCell>
                  <TableCell align="center">
                    {row.unit_cost.toFixed(5)}
                  </TableCell>
                  <TableCell align="center">
                    {row.total_cost.toFixed(3)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};
export default LevelTable;
